import React from 'react';

// constants
import appRoutes from '../../../../app-routes';
import { QueryKeys } from '../../../../queryKeys';
import {
  UNKNOWN_ERRORS,
  mapERRORS,
  UPDATE_IDENTITY_STATUSES,
  MAP_UPDATE_IDENTITY_STATUSES,
} from '../../../../constraints';
import { RECIPIENT_ERROR_CODES } from '../../../../constants';

// hooks
import { useState } from 'react';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  useSingleIdentity,
  useUpdateIdentity,
  useCreateIdentity,
  useUpdateIdentityAsyncWithStatusCheck,
} from '../../hooks';

// components
import { FormattedMessage } from 'react-intl';
import { Modal as ModalModule } from 'billon-ui';
import CreateForm from '../../components/CreateFormNew';
import { RootRedirectPage } from '../../../Documents/screens';
import {
  ErrorContent,
  ErrorContentSubtitle,
} from '../../components/RecipientManager/RecipientsFileForm/styled';
import { ErrorModal } from '../../../../components/ErrorModal';
import StatusInfoModal from '../../../../ui/StatusInfoModal';

const { CreateModal, SuccessModal } = ModalModule;

const RecipientCreateEditPage = () => {
  const { isDiploma, enablePrivate } = useConfigContext();

  const queryClient = useQueryClient();
  const history = useHistory();
  const creatorMatch = useRouteMatch(appRoutes.RECIPIENT_CREATOR);
  const editorMatch = useRouteMatch(appRoutes.RECIPIENT_EDITOR);
  const id = editorMatch?.params?.id;

  const [createEditModalOpen, setCreateEditModalOpen] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureStatusCode, setFailureStatusCode] = useState();
  const [failureAdjunct, setFailureAdjunct] = useState();
  const [errors, setErrors] = useState();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [proceedingIdentityId, setProceedingIdentityId] = useState(null);

  const { data: recipient, isIdentityoading } = useSingleIdentity({
    id: id,
    enabled: id ? true : false,
  });

  const handleSuccess = () => {
    setCreateEditModalOpen(false);
    setSuccessModalOpen(true);
    queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST]);
    id && queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, id]);
  };

  const handleError = ({ response }) => {
    const { errorInfo, statusCode, status, errorStatus } = response?.data || {};
    if (errorInfo) {
      setErrors(errorInfo);
    }
    if (errorStatus) {
      setErrors(errorStatus);
      const failureAdditionalInfo = mapERRORS[errorStatus];
      if (failureAdditionalInfo) {
        setFailureAdjunct(failureAdditionalInfo);
      }
    }
    if (
      statusCode !== RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
      (statusCode || status)
    ) {
      let mappedStatusCode = mapERRORS[UNKNOWN_ERRORS.UNKNOWN_ERROR];
      if (statusCode) {
        mappedStatusCode = mapERRORS[statusCode] ?? mappedStatusCode;
      }
      if (status) {
        mappedStatusCode =
          MAP_UPDATE_IDENTITY_STATUSES[status] ?? mappedStatusCode;
      }
      if (mappedStatusCode === mapERRORS.NOT_FOUND) {
        mappedStatusCode = mapERRORS.IDENTITY_NOT_FOUND;
      }
      setFailureStatusCode(mappedStatusCode);
    }
    setIsLoadingStatus(false);
  };

  const {
    mutate: editIdentity,
    reset: resetUpdate,
    isLoading: isUpdating,
  } = useUpdateIdentity({
    onSuccess: (response) => {
      const { jobId, status, errorStatus } = response || {};
      if (status === UPDATE_IDENTITY_STATUSES.IN_PROGRESS) {
        setIsLoadingStatus(true);
        setProceedingIdentityId(jobId);
        return;
      }
      if (status === UPDATE_IDENTITY_STATUSES.ERROR) {
        handleError({ response: { data: { jobId, status, errorStatus } } });
        return;
      }
      if (status === UPDATE_IDENTITY_STATUSES.SUCCESS) {
        handleSuccess();
      }
    },
    onError: handleError,
  });

  const { isLoading: isLoadingStatusAsync, isRefetching: isRefetchingStatus } =
    useUpdateIdentityAsyncWithStatusCheck(proceedingIdentityId, {
      enabled: !!proceedingIdentityId,
      refetchInterval: (data) => {
        const { status, jobId, errorStatus } = data || {};
        if (status === UPDATE_IDENTITY_STATUSES.IN_PROGRESS) {
          setIsLoadingStatus(true);
          setProceedingIdentityId(jobId);
          return 2000;
        }
        if (status === UPDATE_IDENTITY_STATUSES.SUCCESS) {
          handleSuccess();
          setIsLoadingStatus(false);
          setProceedingIdentityId(null);
          return;
        }
        if (status === UPDATE_IDENTITY_STATUSES.ERROR) {
          handleError({ response: { data: { jobId, status, errorStatus } } });
          setFailureStatusCode(MAP_UPDATE_IDENTITY_STATUSES.ERROR);
          setIsLoadingStatus(false);
          setProceedingIdentityId(null);
          return;
        }
        if (failureStatusCode || errors) {
          setIsLoadingStatus(false);
          setProceedingIdentityId(null);
          return;
        }
        return 2000;
      },
      onError: handleError,
    });

  const reset = () => {
    resetUpdate();
    queryClient.invalidateQueries([QueryKeys.UPDATE_IDENTITY_ASYNC_STATUS]);
  };

  const { mutate: createIdentity, isLoading: isCreating } = useCreateIdentity({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const refetchIdentity = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
  };

  const handleClose = () => {
    reset();
    if (!!id) {
      refetchIdentity();
    }
    history.goBack();
  };

  const handleCreate = (data) => {
    if (id) {
      editIdentity({
        id: id,
        data,
      });
    } else {
      createIdentity({ data });
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    handleClose();
  };

  const handleCloseFailureModal = () => {
    reset();
    refetchIdentity();
    setFailureStatusCode();
  };

  const handleCloseStatusModal = () => {
    reset();
    handleClose();
  };

  const editMessage = isDiploma ? 'Editing student' : 'Editing recipient';
  const createMessage = isDiploma ? 'Adding student' : 'Adding recipient';
  const submitMessage = creatorMatch ? 'Add' : 'Save';
  const successCreateMessage = isDiploma
    ? 'Student has been successfully added.'
    : 'Recipient has been successfully added.';
  const successEditMessage = isDiploma
    ? 'Student has been successfully saved.'
    : 'Recipient has been successfully saved.';
  const successMessage = creatorMatch
    ? successCreateMessage
    : successEditMessage;

  if (!enablePrivate) {
    return <RootRedirectPage />;
  }

  if (isIdentityoading) {
    return null;
  }

  return (
    <>
      <CreateModal
        isOpen={createEditModalOpen}
        onSuccess={() => {}}
        onCancel={handleClose}
        initialValues={recipient ? recipient : {}}
        editMessage={
          <FormattedMessage id={editMessage} defaultMessage={editMessage} />
        }
        createMessage={
          <FormattedMessage id={createMessage} defaultMessage={createMessage} />
        }
      >
        <CreateForm
          onSubmit={handleCreate}
          isSaving={
            isCreating ||
            isUpdating ||
            isLoadingStatusAsync ||
            isRefetchingStatus ||
            isLoadingStatus
          }
          errors={errors}
          submitMessage={
            <FormattedMessage
              id={submitMessage}
              defaultMessage={submitMessage}
            />
          }
        />
      </CreateModal>
      <SuccessModal
        title={
          isDiploma ? (
            <FormattedMessage
              id="Successfully saved"
              defaultMessage="Successfully saved"
            />
          ) : (
            <FormattedMessage
              id={MAP_UPDATE_IDENTITY_STATUSES.SUCCESS}
              defaultMessage={MAP_UPDATE_IDENTITY_STATUSES.SUCCESS}
            />
          )
        }
        isOpen={successModalOpen}
        onClose={handleCloseSuccessModal}
      >
        <FormattedMessage id={successMessage} defaultMessage={successMessage} />
      </SuccessModal>
      <ErrorModal
        isOpen={!!failureStatusCode}
        toggle={handleCloseFailureModal}
        onClose={handleCloseFailureModal}
        closeIconEnabled={false}
      >
        <ErrorContent>
          {!!failureStatusCode && (
            <>
              <p>
                <FormattedMessage
                  id={failureStatusCode}
                  defaultMessage={failureStatusCode}
                />
              </p>
              {failureAdjunct && (
                <ErrorContentSubtitle>
                  <FormattedMessage
                    id={failureAdjunct}
                    defaultMessage={failureAdjunct}
                  />
                </ErrorContentSubtitle>
              )}
            </>
          )}
        </ErrorContent>
      </ErrorModal>
      <StatusInfoModal
        isOpen={!!proceedingIdentityId}
        toggle={handleCloseStatusModal}
        onClose={handleCloseStatusModal}
      >
        <FormattedMessage
          id={MAP_UPDATE_IDENTITY_STATUSES.IN_PROGRESS}
          defaultMessage={MAP_UPDATE_IDENTITY_STATUSES.IN_PROGRESS}
        />
      </StatusInfoModal>
    </>
  );
};

export default RecipientCreateEditPage;
