import React, { useState } from 'react';
import { useCompileNotificationMessages } from '../../../../hooks/useCompileNotificationMessages';
import {
  SelectEditTypeModal,
  EmailNotificationModal,
  SmsNotificationModal,
} from './components';
import { useForm, FormProvider } from 'react-hook-form';
import { useNotificationUserInputs } from '../../../../hooks';
import { FormattedMessage } from 'react-intl';
import { Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const EnterNotificationContent = ({
  isOpen,
  isLoading,
  onBack,
  onClose,
  onNext,
  defaultValues = {},
  documentBlockchainAddress,
  documentType,
}) => {
  const formMethods = useForm({
    defaultValues: {
      smsUserInput: '',
      emailUserInput: '',
      ...defaultValues,
    },
  });

  const { tenantId } = defaultValues || {};

  const { handleSubmit, watch, setValue } = formMethods;

  const { isLoading: areDefaultInputsLoading, error: defaultInputsError } =
    useNotificationUserInputs(documentBlockchainAddress, {
      onSuccess: ({ emailUserInput, smsUserInput }) => {
        setValue('emailUserInput', emailUserInput);
        setValue('smsUserInput', smsUserInput);
      },
    });

  const handleFormSubmit = (values) => {
    onNext(values);
  };

  const emailUserInput = watch('emailUserInput');
  const smsUserInput = watch('smsUserInput');

  const {
    emailPreviewValue,
    smsContentPreviewValue,
    isLoading: isTemplateLoading,
    error,
  } = useCompileNotificationMessages(
    emailUserInput,
    smsUserInput,
    documentType,
    tenantId,
  );

  const [isEmailContentOpened, setIsEmailContentOpened] = useState(false);
  const [isSmsContentOpened, setIsSmsContentOpened] = useState(false);

  const onEditEmailNotification = () => {
    setIsEmailContentOpened(true);
  };
  const onEditSmsNotification = () => {
    setIsSmsContentOpened(true);
  };
  const onEditEmailNotificationClose = () => {
    setIsEmailContentOpened(false);
  };
  const onEditSmsNotificationClose = () => {
    setIsSmsContentOpened(false);
  };

  if (isTemplateLoading || areDefaultInputsLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (error || defaultInputsError) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <SelectEditTypeModal
          isOpen={isOpen}
          isLoading={isLoading || isTemplateLoading}
          onBack={onBack}
          onClose={onClose}
          onNext={handleSubmit(handleFormSubmit)}
          onEditSmsNotification={onEditSmsNotification}
          onEditEmailNotification={onEditEmailNotification}
          isEmailEditable={!!emailUserInput}
          isSmsEditable={!!emailUserInput}
        />
        <EmailNotificationModal
          isOpen={isEmailContentOpened}
          isLoading={isLoading || isTemplateLoading}
          onBack={onEditEmailNotificationClose}
          onClose={onEditEmailNotificationClose}
          onNext={onEditEmailNotificationClose}
          emailPreviewValue={emailPreviewValue}
          isEditable={!!emailUserInput}
        />
        <SmsNotificationModal
          isOpen={isSmsContentOpened}
          isLoading={isLoading || isTemplateLoading}
          onBack={onEditSmsNotificationClose}
          onClose={onEditSmsNotificationClose}
          onNext={onEditSmsNotificationClose}
          smsContentPreviewValue={smsContentPreviewValue}
          isEditable={!!emailUserInput}
        />
      </form>
    </FormProvider>
  );
};

export default EnterNotificationContent;
