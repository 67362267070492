import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { getExternalIdText } from '../../../../helpers/getExternalIdText';

// formatters
import {
  momentInTimezoneFormatter,
  boundLengthFormatter,
  centerMultiFormatter,
  blurMultiFormatter,
  Centered,
  noValueFormatter,
} from '../../../../../../formatters';
import {
  switchFormatter,
  updatedByFormatter,
} from '../../../../../Users/formatters';
import {
  disableableSingleFormatter,
  disableableMultiFormatter,
  recipientStatusWithToolTipFormatter,
  recipientDocumentTypeFormatter,
  recipientUpdateStatusWithToolTipFormatter,
} from '../../../../formatters';

// Components
import * as Styled from './styled';
import { Table as TableModule, Layout as LayoutModule } from 'billon-ui';

const { Table, TableColumn } = TableModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const HistoryTable = ({ historyRecords }) => {
  const { isTauron, isDiploma, isDefault } = useConfigContext();

  const externalIdText = getExternalIdText(isDiploma);

  const switchFormat = (value, row) => (
    <Centered>{switchFormatter(value, row)}</Centered>
  );

  return (
    <Content>
      <Styled.TableCard>
        <Table
          tableData={historyRecords.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
          )}
          sortMethod={() => {}}
          responsive
        >
          <TableColumn
            fieldName="updatedAt"
            formatter={disableableMultiFormatter(momentInTimezoneFormatter)}
          >
            <FormattedMessage id="Edit date" defaultMessage="Edit date" />
          </TableColumn>
          {!isDiploma && (
            <TableColumn
              fieldName="documentType"
              formatter={disableableMultiFormatter(
                recipientDocumentTypeFormatter(isDiploma),
              )}
            >
              <FormattedMessage
                id="Document type"
                defaultMessage="Document type"
              />
            </TableColumn>
          )}
          <TableColumn
            fieldName="documentNumber"
            formatter={blurMultiFormatter(
              disableableMultiFormatter(boundLengthFormatter),
              isDefault,
            )}
          >
            <FormattedMessage
              id={externalIdText}
              defaultMessage={externalIdText}
            />
          </TableColumn>
          <TableColumn
            fieldName="phoneNumber"
            formatter={disableableSingleFormatter}
          >
            <FormattedMessage id="Phone number" defaultMessage="Phone number" />
          </TableColumn>
          {isTauron && (
            <TableColumn
              fieldName="name"
              formatter={disableableSingleFormatter}
            >
              <FormattedMessage
                id="Name and surname"
                defaultMessage="Name and surname"
              />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="firstName"
              formatter={disableableSingleFormatter}
            >
              <FormattedMessage id="First name" defaultMessage="First name" />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="lastName"
              formatter={disableableSingleFormatter}
            >
              <FormattedMessage id="Last name" defaultMessage="Last name" />
            </TableColumn>
          )}
          <TableColumn fieldName="email" formatter={disableableSingleFormatter}>
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn
            fieldName="updatedBy"
            formatter={disableableMultiFormatter(
              noValueFormatter(updatedByFormatter),
            )}
          >
            <FormattedMessage id="Edited by" defaultMessage="Edited by" />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={disableableMultiFormatter(
              recipientStatusWithToolTipFormatter,
              true,
            )}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          <TableColumn
            fieldName="updateStatus"
            formatter={disableableMultiFormatter(
              recipientUpdateStatusWithToolTipFormatter,
              true,
            )}
          >
            <FormattedMessage
              id="Update status"
              defaultMessage="Update status"
            />
          </TableColumn>

          <TableColumn
            className="fixed-width text-center"
            fieldName="isActive"
            formatter={disableableMultiFormatter(
              centerMultiFormatter(switchFormat),
            )}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
        </Table>
      </Styled.TableCard>
    </Content>
  );
};

HistoryTable.propTypes = {
  historyRecords: PropTypes.object.isRequired,
};

export default HistoryTable;
