import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  IDENTITY_DOCUMENT_TYPE,
  MAP_IDENTITY_DOCUMENT_TYPE,
  RECIPIENT_FILTER_FIELD_NAMES,
} from '../../constraints';

// Components
import * as Styled from '../SelectCategoryField/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const defaulSigningTypeKey = IDENTITY_DOCUMENT_TYPE.PESEL;

export const defaultIdentityDocumentType = {
  label: (
    <FormattedMessage id={MAP_IDENTITY_DOCUMENT_TYPE[defaulSigningTypeKey]} />
  ),
  value: defaulSigningTypeKey,
};

const documentTypeList = [
  IDENTITY_DOCUMENT_TYPE.PESEL,
  IDENTITY_DOCUMENT_TYPE.OTHER,
];

export const documentTypeOptions = documentTypeList.map((option) => ({
  label: <FormattedMessage id={MAP_IDENTITY_DOCUMENT_TYPE[option]} />,
  value: option,
}));

export const SelectIdentityDocumentTypeField = ({
  name = RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE,
  disabled,
  labelMessageId = 'Document type',
  isDefaultLabelEnabled = true,
}) => {
  return (
    <>
      {isDefaultLabelEnabled && (
        <Text as="h6" fontSize="12px" fontWeight="500">
          <FormattedMessage
            id={labelMessageId}
            defaultMessage={labelMessageId}
          />
        </Text>
      )}
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={documentTypeOptions}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
