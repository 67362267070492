import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Card: BillonCard, Icon, Text } = Utils;
const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;

export const FieldFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const FC = styled.div`
  margin-right: 17px;
  align-self: center;
`;

export const FC2 = styled.div`
  padding-top: 2px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
`;

export const Card = styled(BillonCard)`
  margin: 0;
`;

export const CenterText = styled(BillonCard)`
  width: 100%;
`;
export const UnderlineLink = styled(Link)`
  text-decoration: underline;
`;

export const ImageIcon = styled(Icon)`
  color: #cad5dd;
`;

export const Title = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const CardBodyWrapper = styled.div`
  padding-top: 35px;
`;

export const ValidationIndicatorRow = styled.div`
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 80%;
  margin-bottom: 15px;
`;

export const ValidationIndicatorLine = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 10px;
  height: 4px;
  background-color: ${(props) => props.theme.palette.grayLighter};
`;

export const Form = styled.div`
  min-height: 500px;
  position: relative;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
  margin-top: 22px;
`;

export const ErrorLabel = styled(Text)`
  color: ${(props) => props.isErrorActive && props.theme.palette.error};
`;
