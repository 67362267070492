import React, { useState } from 'react';

// utils
import PropTypes from 'prop-types';
import { CUSTOMER, PASSWORD_FORM_MODES } from '../../../../constraints';

// hooks
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import useSmsCodeRequest from '../../../../modules/Settings/hooks/useSmsCodeRequest';
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';

// components
import TypeVerificationCode from '../TypeVerificationCode';
import SetPasswordSuccess from '../SetPasswordSuccess';
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../SingleCustomCard';
import { SendSmsCodeFailureModal } from '../SendSmsCodeFailureModal';

const TwoStepPasswordSet = ({ titleId, onFinish }) => {
  const { customer } = useConfigContext();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [sendSmsCodeFailure, setSmsCodeFailure] = useState(false);
  const { token } = useTokenParamContext();

  const handleOnSendSmsCodeSuccess = () => {
    setStep(step + 1);
  };

  const handleOnSendSmsCodeFailure = ({ response }) => {
    setSmsCodeFailure(true);
  };

  const { mutate: sendSmsCode } = useSmsCodeRequest(
    {
      onSuccess: handleOnSendSmsCodeSuccess,
      onFailure: handleOnSendSmsCodeFailure,
    },
    token,
  );

  const handleOnSubmitCodeSuccess = () => {
    setStep(step + 1);
  };

  const handlePatchPasswordSuccess = () => {
    setStep(3);
  };

  const handlePatchPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const handleSmsCodeFailureModalClose = () => {
    setSmsCodeFailure(false);
  };

  const { mutate: patchPassword } = usePatchPassword({
    onSuccess: handlePatchPasswordSuccess,
    onError: handlePatchPasswordFailure,
  });

  const handleOnValidatePasswordSuccess = (passwords) => {
    setPasswords(passwords);
    if (customer === CUSTOMER.TAURON) {
      patchPassword({
        passwordData: {
          oldPassword: passwords.oldPassword,
          password: passwords.password,
        },
      });
    } else {
      sendSmsCode();
    }
  };

  const handleOnBack = () => {
    setStep(step - 1);
  };

  const handleOnClose = () => {
    if (onFinish) {
      onFinish();
    } else {
      history.push('/');
    }
  };

  const formConfig = {
    mode: PASSWORD_FORM_MODES.PASSWORD_SET,
    handleSuccess: handleOnValidatePasswordSuccess,
    passwordLabelId: 'Password',
    repeatPasswordLabelId: 'Repeat password',
    submitMessageId: 'Set password',
  };

  return (
    <>
      {
        {
          1: (
            <SingleCustomCard titleId={titleId ? titleId : 'Set password'}>
              <PasswordForm
                formConfig={formConfig}
                deliveredReasons={reasons}
                deliveredErrorHandler={handlePatchPasswordFailure}
                onBack={handleOnClose}
              />
              <SendSmsCodeFailureModal
                isOpen={sendSmsCodeFailure}
                toggle={handleSmsCodeFailureModalClose}
                onClose={handleSmsCodeFailureModalClose}
                closeIconEnabled={false}
              />
            </SingleCustomCard>
          ),
          2: (
            <TypeVerificationCode
              passwords={passwords}
              handleOnSubmitCodeSuccess={handleOnSubmitCodeSuccess}
              handleOnBack={handleOnBack}
            />
          ),
          3: (
            <SetPasswordSuccess
              onClose={handleOnClose}
              messageId={'Password has been set'}
            />
          ),
        }[step]
      }
    </>
  );
};

TwoStepPasswordSet.propTypes = {
  titleId: PropTypes.string,
};

export default TwoStepPasswordSet;
