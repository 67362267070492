import React, { useState } from 'react';
import { merge } from 'lodash';
import { FormattedMessage } from 'react-intl';

// Utils
import {
  CUSTOMER,
  UserRoles,
  USER_ORDER_BY,
  ORDER_METHOD,
  DOCUMENT_TYPE,
} from '../../../../constraints';
import { QueryKeys } from '../../../../queryKeys';
import { getExternalIdText } from '../../helpers/getExternalIdText';

// formatters
import { momentFormatterWithoutTime } from '../../../../formatters';
import {
  centerSingleFormatter,
  centerMultiFormatter,
  Centered,
  blurMultiFormatter,
  boundLengthFormatter,
} from '../../../../formatters';
import { createdByFormatter } from '../../../Users/formatters';
import {
  recipientStatusWithToolTipFormatter,
  disableableSingleFormatter,
  disableableMultiFormatter,
} from '../../formatters';

// hooks
import { useQueryClient } from 'react-query';
import { useIdentityList } from '../../hooks/useIdentityList';
import useFilters from '../../../../hooks/useFilters';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useRefresh } from '../../../../hooks/useRefresh';

// components
import { ProtectedComponent } from '../../../Auth/components';
import { RefreshButton } from '../../../../components/RefreshButton/RefreshButton';
import RecipientTableDetails from '../RecipientTableDetails';
import { FilterForm } from '../FilterForm';
import * as Styled from './styled';
import IsActiveSwitch from '../IsActiveSwitch';
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;

const RecipientTable = ({ handleCreate, handleManage }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
      sortBy: USER_ORDER_BY.createdAt,
    },
  };

  const { customer, isDiploma, isTauron } = useConfigContext();
  const { isRefreshDisabled, handleRefresh } = useRefresh(
    QueryKeys.IDENTITY_LIST,
  );

  const [areFiltersOpen, setAreFiltersOpen] = useState();
  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery, navigateWithNewSearchQuery } =
    useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading } = useIdentityList(fullFilters);
  const queryClient = useQueryClient();

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: USER_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const onSwitchSuccess = () =>
    queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST, fullFilters]);

  const onNavigateToDocuments = (documentNumber) => {
    navigateWithNewSearchQuery(
      {
        filters: {
          documentTypeList: DOCUMENT_TYPE.PRIVATE,
          identity: { documentNumber },
        },
      },
      '/documents',
    );
  };

  const switchFormat = (value, row) => (
    <Centered>
      <IsActiveSwitch value={value} row={row} onSuccess={onSwitchSuccess} />
    </Centered>
  );

  const showDocsFormat = (value, row) => (
    <Styled.IconButton
      icon="folder-open"
      onClick={() => onNavigateToDocuments(value)}
    />
  );

  const addText = isDiploma ? 'Add student' : 'Add recipient';
  const manageText = isDiploma ? 'Manage students' : 'Manage recipients';
  const titleText = isDiploma ? 'Students' : 'Recipients';

  const externalIdText = getExternalIdText(isDiploma);

  const numberOfPages = Math.ceil(data.count / fullFilters.pagination.limit);

  const isDocumentNumberBlurEnabled = customer === CUSTOMER.DEFAULT;

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent
                roles={[
                  UserRoles.AGENT,
                  UserRoles.BUSINESS_ADMIN,
                  UserRoles.ADMIN,
                ]}
                render={false}
              >
                <Styled.AfterWrapper>
                  {handleManage && (
                    <Button
                      onClick={handleManage}
                      fontWeight={500}
                      padding="0.5rem 3.5rem;"
                    >
                      <FormattedMessage
                        id={manageText}
                        defaultMessage={manageText}
                      />
                    </Button>
                  )}

                  {handleCreate && (
                    <Button
                      onClick={handleCreate}
                      fontWeight={500}
                      padding="0.5rem 3.5rem;"
                    >
                      <FormattedMessage id={addText} defaultMessage={addText} />
                    </Button>
                  )}
                </Styled.AfterWrapper>
              </ProtectedComponent>
            }
          >
            <FormattedMessage id={titleText} defaultMessage={titleText} />
            <RefreshButton
              isRefreshDisabled={isRefreshDisabled}
              handleRefresh={handleRefresh}
            />
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm initialValues={filters} closeFilters={toggleFiltersForm} />
      )}

      <Styled.TableCard>
        <Table
          tableData={data.rows}
          sortMethod={onSort}
          responsive
          detailsComponent={(record) => (
            <RecipientTableDetails record={record} />
          )}
        >
          <TableColumn
            fieldName="createdAt"
            formatter={disableableMultiFormatter(momentFormatterWithoutTime)}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.createdAt)}
          >
            <FormattedMessage id="Created at" defaultMessage="Created at" />
          </TableColumn>
          <TableColumn
            fieldName="documentNumber"
            formatter={blurMultiFormatter(
              disableableMultiFormatter(boundLengthFormatter),
              isDocumentNumberBlurEnabled,
            )}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.documentNumber)}
          >
            <FormattedMessage
              id={externalIdText}
              defaultMessage={externalIdText}
            />
          </TableColumn>
          <TableColumn
            fieldName="phoneNumber"
            formatter={disableableSingleFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.phoneNumber)}
          >
            <FormattedMessage id="Phone number" defaultMessage="Phone number" />
          </TableColumn>
          {isTauron && (
            <TableColumn
              fieldName="name"
              formatter={disableableSingleFormatter}
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.name)}
            >
              <FormattedMessage
                id="Name and surname"
                defaultMessage="Name and surname"
              />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="firstName"
              formatter={disableableSingleFormatter}
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.firstName)}
            >
              <FormattedMessage id="First name" defaultMessage="First name" />
            </TableColumn>
          )}
          {!isTauron && (
            <TableColumn
              fieldName="lastName"
              formatter={disableableSingleFormatter}
              sortable
              asc={isAscendingOrder(USER_ORDER_BY.lastName)}
            >
              <FormattedMessage id="Last name" defaultMessage="Last name" />
            </TableColumn>
          )}
          <TableColumn
            fieldName="email"
            formatter={disableableSingleFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.email)}
          >
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn
            fieldName="createdBy"
            formatter={disableableMultiFormatter(createdByFormatter)}
          >
            <FormattedMessage id="Created by" defaultMessage="Created by" />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={disableableMultiFormatter(
              recipientStatusWithToolTipFormatter,
              true,
            )}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          <TableColumn
            className="fixed-width text-center"
            fieldName="isActive"
            formatter={disableableMultiFormatter(
              centerMultiFormatter(switchFormat),
            )}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
          <TableColumn
            className="text-center"
            fieldName="documentsAmount"
            formatter={centerSingleFormatter}
          >
            <FormattedMessage
              id="Documents count"
              defaultMessage="Documents count"
            />
          </TableColumn>
          <TableColumn
            className="text-center"
            formatter={centerMultiFormatter(showDocsFormat)}
            fieldName="documentNumber"
          >
            <FormattedMessage
              id="Show documents"
              defaultMessage="Show documents"
            />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

export default RecipientTable;
