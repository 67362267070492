import { CUSTOMER } from '../../../constraints';

const config = {
  backendUrl: 'https://apitergopublication.tdm.bybillon.com/application/v1.0',
  enablePublic: true,
  enablePrivate: false,
  enableSigning: false,
  manageRecipients: false,
  pageTitle: 'Trusted Document Register - Publication Platform',
  customer: CUSTOMER.DEFAULT,
  privateDocuments: {
    enableHeaderLink: false,
    enableNewVersion: false,
    enableErratum: false,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableHeaderLink: false,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: false,
    publicationDate: true,
    categoryFullPath: true,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: false,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: true,
    documentNumber: true,
  },
  SMS_CODE_STATUSES: null,
};

export default config;
