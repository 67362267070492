import React from 'react';
import {
  DOCUMENT_STATUS,
  DOCUMENT_PUBLICATION_STATUS,
} from '../../../../../../constraints';
import { CopiedInfo } from '../CopiedInfo';

export const JobIdDetails = ({ record }) => {
  if (
    ![
      DOCUMENT_STATUS.UPLOADING,
      DOCUMENT_STATUS.PREPARED_TO_SIGN,
      DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR,
      DOCUMENT_STATUS.PUBLISHING_INITIATED,
    ].includes(record?.status)
  ) {
    return null;
  }

  return <CopiedInfo label="Publication ID" value={record?.jobId} />;
};
