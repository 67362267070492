// utils
import React, { useState, useEffect, useMemo } from 'react';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { yupResolver } from '@hookform/resolvers/yup';
import { union } from 'lodash';
import PropTypes from 'prop-types';

// constants
import {
  password_role_based_info_list,
  password_other_info_list,
  CUSTOMER,
  VALIDATION_ERRORS,
  VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS,
  PASSWORD_FORM_MODES,
  REPORT_ROLE,
} from '../../../../constraints';
import { UserRoles } from '../../../../userRoles';

// hooks
import { useHistory } from 'react-router-dom';
import { useSchema } from '../PasswordForm/useSchema';
import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { useValidatePassword } from '../../../../modules/Settings/hooks/useValidatePassword';
import { useForm, Controller } from 'react-hook-form';
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';
import useMeRequest from '../../../Settings/hooks/useMeRequest';

// components
import { FormattedMessage, useIntl } from 'react-intl';
import { Form as FormModule, Utils } from 'billon-ui';
import ValidationInfo from '../ValidationInfo';
import * as Styled from './styled';
import Accordion from '../../../../components/Accordion/Accordion';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup, PasswordField } = FormModule;

const PasswordForm = ({
  deliveredRole,
  formConfig,
  deliveredReasons,
  deliveredErrorHandler,
  onBack,
}) => {
  const {
    mode,
    handleSuccess: onSuccess,
    submitMessageId,
    passwordLabelId,
    repeatPasswordLabelId,
  } = formConfig;

  const { customer } = useConfigContext();
  const { token } = useTokenParamContext();
  const {
    role: contextRole,
    isTokenExpired,
    getSettingValueByKey,
  } = useSettingsContext();
  const { formatMessage } = useIntl();

  const { data: me, isLoading: isMeLoading } = useMeRequest({}, token);
  const { reportRole } = me || {};

  const role = deliveredRole ? deliveredRole : contextRole;

  const keyPrefix =
    [UserRoles.ADMIN].includes(role) || [REPORT_ROLE.ADMIN].includes(reportRole)
      ? UserRoles.ADMIN
      : 'USER';

  const isAccordionNested = customer !== CUSTOMER.DEFAULT;
  const accordionHeader = formatMessage({ id: 'Password setting rules' });

  const validationConstraints = {
    oldPasswordEnabled: mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE,
    minLength: getSettingValueByKey(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[VALIDATION_ERROR_TYPES.TOO_SHORT]
      }`,
    ),
    complexity: getSettingValueByKey(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[
          VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS
        ]
      }`,
    ),
  };

  const schema = useSchema({ validationConstraints: validationConstraints });

  const { handleSubmit, formState, control, getValues, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const { isSubmitting, errors, isSubmitSuccessful, isSubmitted } =
    formState || {};

  const [reasons, setReasons] = useState(deliveredReasons);

  const history = useHistory();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    setReasons(union(reasons, deliveredReasons));
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS)) {
      if (mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE) {
        setError('oldPassword', {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      } else {
        setError('password', {
          type: 'custom',
          message: VALIDATION_ERRORS.EMPTY,
        });
        setError('repeatPassword', {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      }
    }
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.IDENTICAL)) {
      setError('password', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError('repeatPassword', {
        type: 'custom',
        message: MAP_VALIDATION_ERROR_TYPES.IDENTICAL,
      });
    }
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN)) {
      setError('password', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError('repeatPassword', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
    }
  }, [deliveredReasons]);

  const handleError = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: validatePassword } = useValidatePassword(
    {
      onSuccess: () => onSuccess(getValues()),
      onError: deliveredErrorHandler ? deliveredErrorHandler : handleError,
    },
    token,
  );

  const onSubmit = async (values) => {
    const { oldPassword, password } = values;

    const body = { oldPassword: oldPassword, password: password };

    if (customer === CUSTOMER.TAURON) {
      onSuccess(getValues());
    } else {
      validatePassword({
        data: removeUndefinedValues(body),
      });
    }
  };

  const PasswordRoleBasedInfo = () =>
    role &&
    password_role_based_info_list.map((requirement) => {
      const keyPrefix =
        [UserRoles.ADMIN].includes(role) ||
        [REPORT_ROLE.ADMIN].includes(reportRole)
          ? UserRoles.ADMIN
          : 'USER';
      const settingsKey = `${keyPrefix}_${MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}`;
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={settingsKey}
          requirement={requirement}
          isErrorActive={isErrorActive}
        />
      );
    });

  const PasswordOtherInfo = () =>
    password_other_info_list.map((requirement) => {
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}
          requirement={requirement}
          isErrorActive={isErrorActive}
        />
      );
    });

  const isError = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);

  const isFormSubmitted = useMemo(() => {
    return !isSubmitting && !isSubmitSuccessful && isSubmitted;
  }, [isSubmitSuccessful, isSubmitted, isSubmitting]);

  return (
    <Styled.Form>
      {mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE && (
        <Controller
          name="oldPassword"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <PasswordField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={
                  <FormattedMessage
                    id="Old password"
                    defaultMessage="Old password"
                  />
                }
              />
            );
          }}
        />
      )}

      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <PasswordField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id={passwordLabelId}
                  defaultMessage={passwordLabelId}
                />
              }
            />
          );
        }}
      />

      <Styled.ValidationIndicatorRow>
        <Styled.ValidationIndicatorLine />
        <Styled.ValidationIndicatorLine />
        <Styled.ValidationIndicatorLine />
      </Styled.ValidationIndicatorRow>

      <Controller
        name="repeatPassword"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <PasswordField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id={repeatPasswordLabelId}
                  defaultMessage={repeatPasswordLabelId}
                />
              }
            />
          );
        }}
      />

      <Accordion
        header={accordionHeader}
        isNested={isAccordionNested}
        isTriggered={isError}
        isFormSubmitted={isFormSubmitted}
      >
        {isTokenExpired && (
          <Styled.ErrorLabel isErrorActive>
            <FormattedMessage
              id="Link is expired"
              defaultMessage="Link is expired"
            />
          </Styled.ErrorLabel>
        )}

        <PasswordRoleBasedInfo />
        <PasswordOtherInfo />
      </Accordion>

      <Styled.CardBodyWrapper>
        <FormGroup>
          {isSubmitting || isMeLoading ? (
            <ButtonLoader block size="lg" />
          ) : (
            <Button
              type="submit"
              size="lg"
              block
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage
                id={submitMessageId}
                defaultMessage={submitMessageId}
              />
            </Button>
          )}
        </FormGroup>
        <FormGroup>
          <Styled.BackButton secondary onClick={handleBack}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Styled.BackButton>
        </FormGroup>
      </Styled.CardBodyWrapper>
    </Styled.Form>
  );
};

PasswordForm.propTypes = {
  token: PropTypes.object,
  deliveredErrorHandler: PropTypes.func,
  role: PropTypes.string,
  formConfig: PropTypes.object.isRequired,
};

export default PasswordForm;
