import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const CustomToolTip = ({
  id,
  placement = 'right',
  trigger,
  delay,
  minWidth,
  children,
}) => (
  <>
    <Styled.TriggerWrapper id={id} minWidth={minWidth}>
      {trigger}
    </Styled.TriggerWrapper>
    <Styled.Tooltip
      placement={placement}
      trigger="hover"
      target={id}
      delay={{ show: 10, hide: delay ? 1000 : 10 }}
    >
      <Styled.JustifiedContent>{children}</Styled.JustifiedContent>
    </Styled.Tooltip>
  </>
);

CustomToolTip.propTypes = {
  minWidth: PropTypes.bool,
};

export default CustomToolTip;
