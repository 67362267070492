import React from 'react';
import { useCompileNotificationMessages } from '../../hooks/useCompileNotificationMessages';
import renderHTML from 'react-render-html';

// Components
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { RenderedSMS } from '../RenderedNotificationContainer/RenderedNotificationContainer';
import { RenderedNotificationContainer } from '../RenderedNotificationContainer/RenderedNotificationContainer';
import { Grid, Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Col, Row } = Grid;

const NotificationTemplePreview = ({ notificationTemplateType, tenantId }) => {
  const {
    emailSubject,
    emailPreviewValue,
    smsContentPreviewValue,
    isLoading,
    error,
  } = useCompileNotificationMessages(
    '',
    '',
    notificationTemplateType,
    tenantId,
  );

  if (isLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
      </div>
    );
  }

  return (
    <>
      <Styled.TemplateWrapper>
        <Styled.SingleTemplateHeader>
          <FormattedMessage
            id="Email notification"
            defaultMessage="Email notification"
          />
        </Styled.SingleTemplateHeader>
        <Styled.EmailSubject>
          <Styled.EmailSubjectTag>
            <FormattedMessage id="Subject" defaultMessage="Subject" />
          </Styled.EmailSubjectTag>
          {`: ${emailSubject}`}
        </Styled.EmailSubject>

        <Row>
          <Col>
            <RenderedNotificationContainer isFullHeight>
              {renderHTML(emailPreviewValue)}
            </RenderedNotificationContainer>
          </Col>
        </Row>
      </Styled.TemplateWrapper>
      <Styled.TemplateWrapper>
        <Styled.SingleTemplateHeader>
          <FormattedMessage
            id="SMS notification"
            defaultMessage="SMS notification"
          />
        </Styled.SingleTemplateHeader>
        <Row>
          <Col>
            <RenderedSMS>{smsContentPreviewValue}</RenderedSMS>
          </Col>
        </Row>
      </Styled.TemplateWrapper>
    </>
  );
};

export default NotificationTemplePreview;
