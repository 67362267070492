import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styled from './styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Modal as ModalModule, Utils } from 'billon-ui';
import { useIdentitiesOptions } from '../../hooks/useIdentitiesOptions';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../queryKeys';
import { useCreateIdentity } from '../../modules/Recipients/hooks';
import RecipientCreateFrom from '../../modules/Recipients/components/CreateFormNew';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';

const { SuccessModal, CreateModal } = ModalModule;
const { Text } = Utils;

export const SelectIdentityField = ({
  createIdentityEnabled,
  disabled,
  name = 'identity',
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { isDiploma } = useConfigContext();

  const { identities, isIdentitiesFetching, onChangeIdentityInput } =
    useIdentitiesOptions('documentNumber', true);

  const [isCreateRecipientModalOpen, setCreateRecipientModalOpen] =
    useState(false);
  const [
    isSuccessCreateRecipientModalOpen,
    setSuccessCreateRecipientModalOpen,
  ] = useState(false);

  const [errors, setErrors] = useState({});

  const { mutate: createIdentity, isLoading: isCreatingIdentity } =
    useCreateIdentity({
      onSuccess: () => {
        onCreateRecipientFinished();
        queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST]);
      },
      onError: ({ response }) => {
        const errorInfo = response?.data?.errorInfo;
        setErrors(errorInfo);
      },
    });

  const toggleCreateRecipientModal = () => {
    setCreateRecipientModalOpen(!isCreateRecipientModalOpen);
  };

  const onCreateRecipientFinished = () => {
    toggleCreateRecipientModal();
    toggleSuccessModalRecipientClose();
  };

  const toggleSuccessModalRecipientClose = () => {
    setSuccessCreateRecipientModalOpen(!isSuccessCreateRecipientModalOpen);
  };

  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        {isDiploma ? (
          <FormattedMessage id="Student" defaultMessage="Student" />
        ) : (
          <FormattedMessage id="Recipient" defaultMessage="Recipient" />
        )}
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          onInputChange={onChangeIdentityInput}
          options={identities}
          disabled={disabled}
          isLoading={isIdentitiesFetching}
          placeholder={
            isDiploma &&
            formatMessage({
              id: 'Index number',
              defaultMessage: 'Index number',
            })
          }
        />
        {createIdentityEnabled && (
          <Styled.IconButton
            icon="user-plus"
            onClick={toggleCreateRecipientModal}
          />
        )}
      </Styled.FormGroup>
      <CreateModal
        isOpen={isCreateRecipientModalOpen}
        onSuccess={() => {}}
        onCancel={toggleCreateRecipientModal}
        initialValues={{}}
        createMessage={
          isDiploma ? (
            <FormattedMessage
              id="Adding student"
              defaultMessage="Adding student"
            />
          ) : (
            <FormattedMessage
              id="Adding recipient"
              defaultMessage="Adding recipient"
            />
          )
        }
      >
        <RecipientCreateFrom
          onSubmit={(data) => createIdentity({ data })}
          errors={errors}
          isSaving={isCreatingIdentity}
        />
      </CreateModal>
      <SuccessModal
        isOpen={isSuccessCreateRecipientModalOpen}
        onClose={toggleSuccessModalRecipientClose}
      >
        <FormattedMessage
          id="Successfully saved"
          defaultMessage="Successfully saved"
        />
      </SuccessModal>
    </>
  );
};
