import axios from 'axios';
import qs from 'qs';
import apiRoutes from '../api-routes';
import { parseFilters } from './parseFilters';

const apiClient = () =>
  axios.create({
    headers: {
      Authorization: sessionStorage.getItem('token'),
    },
    paramsSerializer: (params) => {
      return qs.stringify(parseFilters(params), { allowDots: true });
    },
  });

const request = (method) => async (params) => {
  const client = apiClient();

  try {
    const response = await client.request({
      method,
      ...params,
    });

    return response;
  } catch (error) {
    if (error.response.status === 403) {
      const { data } = await client.request({
        method: 'GET',
        url: apiRoutes.REFRESH_TOKEN,
      });

      sessionStorage.setItem('token', data.token);

      return request(method)(params);
    } else {
      throw error;
    }
  }
};

export const getRequest = request('GET');
export const postRequest = request('POST');
export const patchRequest = request('PATCH');
export const deleteRequest = request('DELETE');

export default apiClient;
