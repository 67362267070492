import React, { useMemo } from 'react';
import { IDENTITY_DOCUMENT_TYPE } from '../../../../../../constraints';
import DataDetailsSection from '../../../../../../components/DataDetailsSection';
import {
  recipientStatusWithToolTipFormatter,
  recipientGdprSuspendedFormatter,
} from '../../../../../Recipients/formatters';
import { userInfoFormatter } from '../../../../../../formatters';
import * as formatters from '../../../../../Users/formatters';
import { blurSingleFormatter } from '../../../../../../formatters';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { getDocumentTypeText } from '../../../../../Recipients/helpers/getDocumentTypeText';

const DetailsSection = ({ recipient = {} }) => {
  const { isDiploma, isDefault, isTauron } = useConfigContext();

  const { documentType } = recipient;

  const externalIdText = useMemo(
    () => getDocumentTypeText(isDiploma, documentType),
    [isDiploma, documentType],
  );

  const genericEnabledDetailsCol1 = [
    {
      title: externalIdText,
      data: blurSingleFormatter(
        recipient?.documentNumber,
        isDefault && documentType === IDENTITY_DOCUMENT_TYPE.PESEL,
      ),
      enabled: true,
    },
    {
      title: 'Status',
      data: recipientStatusWithToolTipFormatter(recipient?.status, recipient),
      enabled: true,
    },
  ];

  const enabledDetailsCol1 = isTauron
    ? [
        {
          title: 'Name and surname',
          data: recipient?.name,
          enabled: true,
        },
        ...genericEnabledDetailsCol1,
      ]
    : [
        {
          title: 'First name',
          data: recipient?.firstName,
          enabled: true,
        },
        {
          title: 'Last name',
          data: recipient?.lastName,
          enabled: true,
        },
        ...genericEnabledDetailsCol1,
      ];

  const enabledDetailsCol2 = [
    {
      title: 'Email',
      data: recipient?.email,
      enabled: true,
    },
    {
      title: 'Phone number',
      data: recipient?.phoneNumber,
      enabled: true,
    },
    {
      title: 'Data processing suspension',
      data: recipientGdprSuspendedFormatter(recipient?.isGdprSuspended),
      enabled: true,
      warning: true,
    },
  ];

  const enabledDetailsCol3 = [
    {
      title: 'Created at',
      data: formatters.createdAtFormatter('', recipient),
      enabled: true,
    },
    {
      title: 'Created by',
      data: recipient?.createdBy
        ? userInfoFormatter('', recipient.createdBy)
        : '',
      enabled: true,
    },
  ];

  return (
    <DataDetailsSection
      headerMessageId={'Details'}
      activityTagEnabled
      isActive={recipient?.isActive}
      columns={[enabledDetailsCol1, enabledDetailsCol2, enabledDetailsCol3]}
    />
  );
};

export default DetailsSection;
