import styled from 'styled-components';

export const RenderedNotificationContainer = styled.div`
  border: 1px solid #cad5dd;
  border-radius: 3px;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  background-color: #eaeef0;
  min-height: 7rem;
  overflow: ${({ isFullHeight }) => !isFullHeight && 'auto'};
  max-height: ${({ isFullHeight }) => !isFullHeight && '20rem'};

  a {
    pointer-events: none;
  }
`;

export const RenderedSMS = styled(RenderedNotificationContainer)`
  white-space: pre;
`;
