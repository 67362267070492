import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CalendarField } from '../../containers';

export const ControlledCalendarField = ({
  name,
  disabled,
  placeholder,
  onInputChange,
  range,
  minDate,
  maxDate,
  enableTime,
  displayTime,
  showMonths,
  onClear,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const handleInputChange = (...args) => {
    onInputChange && onInputChange(...args);
  };
  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  const dateFormat = displayTime ? 'd.m.Y, H:ss:ss' : 'd.m.Y';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <CalendarField
          input={{
            ...field,
            onInputChange: handleInputChange,
            onClear: handleClear,
          }}
          meta={{
            touched: fieldState.invalid,
            error: errors?.[field.name]?.message,
          }}
          enableTime={enableTime}
          dateFormat={dateFormat}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          placeholder={placeholder}
          range={range}
          showMonths={showMonths}
          onClear={onClear}
        />
      )}
    />
  );
};
