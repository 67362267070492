import { isEmpty } from 'lodash';

const transformStoredRecipients = (storedRecipients) => {
  if (isEmpty(storedRecipients)) {
    return;
  }

  let idContactTypeMap = {};

  storedRecipients.forEach(({ externalId, type }) => {
    const actualArray = idContactTypeMap[externalId];
    if (actualArray) {
      idContactTypeMap[externalId] = [...actualArray, type];
    } else {
      idContactTypeMap[externalId] = [type];
    }
  });

  return idContactTypeMap;
};

const transformGroups = (groups) => {
  if (isEmpty(groups)) {
    return;
  }

  return groups.filter((g) => g.checked === true).map((g) => g.value);
};

export const parseResendNotificationData = (
  { smsUserInput, emailUserInput, groups },
  storedRecipients,
) => ({
  smsUserInput,
  emailUserInput,
  idContactTypeMap: transformStoredRecipients(storedRecipients),
  groups: transformGroups(groups),
});
