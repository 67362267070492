import { CUSTOMER } from '../../../constraints';

const config = {
  backendUrl: 'https://apirspublication.uat.tdm.bybillon.com/application/v1.0',
  customer: CUSTOMER.DEFAULT,

  // publication features
  enablePublic: true,
  enablePrivate: true,
  enableSigning: true,
  enableActiveDelivery: true,
  enablePublicationDelay: false,

  // reports
  enableReports: true,

  // recipients
  manageRecipients: true,

  privateDocuments: {
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: false,
    publicationDate: true,
    categoryFullPath: false,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: true,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: false,
    documentNumber: false,
  },
  SMS_CODE_STATUSES: null,
};

export default config;
