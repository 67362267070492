export const parseUpdateRequestData = ({
  title,
  identity,
  validSince,
  validUntil,
  category,
  tenant,
  versionName,
  publicationMode,
  previousDocumentBlockchainAddress,
  retainUntil,
  ...rest
}) => ({
  publicationMode,
  previousDocumentBlockchainAddress,
  title,
  identity,
  validSince,
  validUntil,
  category,
  tenant,
  versionName,
  retainUntil,
});
