import * as yup from 'yup';
import {
  VALIDATION_ERRORS,
  MAP_VALIDATION_USER_ERRORS,
  IDENTITY_DOCUMENT_TYPE,
} from '../../../../constraints';
import { Utils } from 'billon-ui';
import validatePESEL from './validators/validatePESEL';
import { useIntl } from 'react-intl';
import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';

const { validators } = Utils;

export const useSchema = () => {
  const { isDiploma, isTauron } = useConfigContext();
  const { formatMessage } = useIntl();
  const genericYupObject = {
    phoneNumber: yup
      .string()
      .test(
        'phoneNumber',
        formatMessage({
          id: VALIDATION_ERRORS.PHONE_INVALID,
          defaultMessage: VALIDATION_ERRORS.PHONE_INVALID,
        }),
        (v) => !validators.phoneVerification(v),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
        }),
      ),
    email: yup
      .string()
      .test(
        'email',
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (v) => !validators.emailValidator(v),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
        }),
      ),
    documentNumber: yup
      .string()
      .test(
        'documentNumber',
        function () {
          if (isDiploma) {
            return formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.INVALID_INDEX,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_INDEX,
            });
          }
          const documentType = this?.parent?.documentType;
          if (documentType?.value === IDENTITY_DOCUMENT_TYPE.OTHER) {
            return formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.INVALID_NATIONAL_ID,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_NATIONAL_ID,
            });
          }
          return formatMessage({
            id: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
            defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
          });
        },
        function (documentNumber) {
          const documentType = this?.parent?.documentType;

          if (
            isDiploma ||
            !documentType ||
            documentType?.value === IDENTITY_DOCUMENT_TYPE.OTHER
          )
            return true;

          return validatePESEL(documentNumber);
        },
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
        }),
      ),
  };
  return yup.object(
    isTauron
      ? {
          name: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_NAME,
            }),
          ),
          ...genericYupObject,
        }
      : {
          firstName: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
            }),
          ),
          lastName: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
            }),
          ),
          ...genericYupObject,
        },
  );
};
