import { CUSTOMER } from '../../../constraints';

const config = {
  backendUrl:
    'https://apidiplomapublication.uat.tdm.bybillon.com/application/v1.0',
  customer: CUSTOMER.DIPLOMA,

  // publication features
  enablePublic: false,
  enablePrivate: true,
  enableSigning: false,
  enableActiveDelivery: false,
  enablePublicationDelay: false,

  // recipients
  manageRecipients: true,

  privateDocuments: {
    enableHeaderLink: false,
    enableNewVersion: false,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableHeaderLink: false,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: false,
    publicationDate: true,
    categoryFullPath: true,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: false,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: true,
    documentNumber: true,
  },
  SMS_CODE_STATUSES: null,
};

export default config;
