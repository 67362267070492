import React, { useState } from 'react';

// utils
import { CUSTOMER } from '../../../../constraints';

// hooks
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import useSmsCodeRequest from '../../../../modules/Settings/hooks/useSmsCodeRequest';

// components
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../SingleCustomCard';
import { SendSmsCodeFailureModal } from '../SendSmsCodeFailureModal';
import TypeVerificationCode from '../TypeVerificationCode';
import SetPasswordSuccess from '../SetPasswordSuccess';

const TwoStepPasswordChange = ({ mode }) => {
  const { customer } = useConfigContext();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [sendSmsCodeFailure, setSmsCodeFailure] = useState(false);

  const handlePatchPasswordSuccess = () => {
    setStep(3);
  };

  const handlePatchPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: patchPassword } = usePatchPassword({
    onSuccess: handlePatchPasswordSuccess,
    onError: handlePatchPasswordFailure,
  });

  const handleOnSendSmsCodeSuccess = () => {
    setStep(step + 1);
  };

  const handleOnSendSmsCodeFailure = () => {
    setSmsCodeFailure(true);
  };

  const handleSmsCodeFailureModalClose = () => {
    setSmsCodeFailure(false);
  };

  const { mutate: sendSmsCode } = useSmsCodeRequest({
    onSuccess: handleOnSendSmsCodeSuccess,
    onError: handleOnSendSmsCodeFailure,
  });

  const handleOnSubmitCodeSuccess = () => {
    setStep(step + 1);
  };

  const handleOnValidatePasswordSuccess = (passwords) => {
    setPasswords(passwords);
    if (customer === CUSTOMER.TAURON) {
      patchPassword({
        passwordData: {
          oldPassword: passwords.oldPassword,
          password: passwords.password,
        },
      });
    } else {
      sendSmsCode();
    }
  };

  const handleOnBack = () => {
    setStep(step - 1);
  };

  const handleOnClose = () => {
    history.push('/');
  };

  const formConfig = {
    mode,
    handleSuccess: handleOnValidatePasswordSuccess,
    passwordLabelId: 'New password',
    repeatPasswordLabelId: 'Repeat new password',
    submitMessageId: 'Change password',
  };

  return (
    <>
      {
        {
          1: (
            <SingleCustomCard titleId="Password change">
              <PasswordForm
                formConfig={formConfig}
                deliveredReasons={reasons}
                deliveredErrorHandler={handlePatchPasswordFailure}
              />
              <SendSmsCodeFailureModal
                isOpen={sendSmsCodeFailure}
                toggle={handleSmsCodeFailureModalClose}
                onClose={handleSmsCodeFailureModalClose}
                closeIconEnabled={false}
              />
            </SingleCustomCard>
          ),
          2: (
            <TypeVerificationCode
              passwords={passwords}
              handleOnSubmitCodeSuccess={handleOnSubmitCodeSuccess}
              handleOnBack={handleOnBack}
            />
          ),
          3: (
            <SetPasswordSuccess
              onClose={handleOnClose}
              messageId={'Password successfully changed'}
            />
          ),
        }[step]
      }
    </>
  );
};

export default TwoStepPasswordChange;
