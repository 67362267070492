import React from 'react';
import { NOTIFICATION_TYPES, UserRoles } from '../../../../../../constraints';
import useNotificationPreview from '../../../../../../modules/Documents/hooks/useNotificationPreview';
import {
  ActionButton as StyledActionButton,
  NotificationContentModal,
} from '..';
import { useIntl } from 'react-intl';
import renderHTML from 'react-render-html';
import {
  RenderedNotificationContainer,
  RenderedSMS,
} from '../../../RenderedNotificationContainer/RenderedNotificationContainer';
import { useAuthenticatedContext } from '../../../../../Auth/hooks/useAuthenticated';
import { LockIcon, ErrorIcon, LoadingIcon } from '../ActionIcons';

const ContentActionButton = ({
  children,
  jobId,
  clientId,
  contactDetails,
  type,
}) => {
  const {
    content: notificationContent,
    isPreviewOpened,
    isPreviewLoading,
    isError,
    showContent,
    closeContent,
  } = useNotificationPreview();

  const { formatMessage } = useIntl();

  const { role } = useAuthenticatedContext();
  const isFeatureDisabled = role === UserRoles.DPI;

  const onActionClick = (e) => {
    if (!isFeatureDisabled) {
      e.stopPropagation();
      showContent(jobId, clientId, contactDetails);
    }
  };

  const title = () => {
    if (type === NOTIFICATION_TYPES.PHONE) {
      return formatMessage({ id: 'SMS notification' });
    } else {
      return formatMessage({ id: 'Email notification' });
    }
  };
  const modalTitle = title();

  const NotificationContent = () => {
    let contentToRender = notificationContent;
    if (type === NOTIFICATION_TYPES.EMAIL) {
      (contentToRender.attachments || []).forEach((attachment) => {
        contentToRender.content = contentToRender.content.replace(
          `cid:${attachment.name}`,
          `data:image/jpeg;charset=utf-8;base64,${attachment.source}`,
        );
      });
    }
    return renderHTML(contentToRender?.content);
  };

  if (isFeatureDisabled) {
    return <LockIcon />;
  }

  if (isPreviewLoading) {
    return <LoadingIcon width={18} />;
  }

  if (isError) {
    return <ErrorIcon />;
  }

  return (
    <StyledActionButton onClick={onActionClick}>
      {children}
      <NotificationContentModal
        isOpen={isPreviewOpened}
        toggle={closeContent}
        isLoading={isPreviewLoading}
        onClose={closeContent}
        title={modalTitle}
      >
        {type === NOTIFICATION_TYPES.EMAIL ? (
          <RenderedNotificationContainer>
            <NotificationContent />
          </RenderedNotificationContainer>
        ) : (
          <RenderedSMS>
            <NotificationContent />
          </RenderedSMS>
        )}
      </NotificationContentModal>
    </StyledActionButton>
  );
};

export default ContentActionButton;
