import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useDownloadRecipientsList } from '../../hooks/useDownloadRecipientsList';
import { useSettingsContext } from '../../../Settings/hooks/useSettings';

// Names
import {
  PUBLICATION_FIELD_NAMES,
  PUBLICATION_MODES,
  CUSTOMER,
  DOCUMENT_TYPE,
  SIGNING_ORDER,
  SETTINGS_CONSTANTS,
} from '../../../../constraints';

// Components
import { SelectActiveDeliveryField } from '../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import { SelectSigningTypeField } from '../../../../components/SelectSigningTypeField/SelectSigningTypeField';
import { SelectSigningOrderField } from '../../../../components/SelectSigningOrderField/SelectSigningOrderField';
import { SelectRetentionField } from '../../../../components/SelectRetentionField/SelectRetentionField';
import { ControlledTextField } from '../../../../components/ControlledTextField';
import { ControlledCalendarField } from '../../../../components/ControlledCalendarField/ControlledCalendarField';
import { SelectCategoryField } from '../../../../components/SelectCategoryField/SelectCategoryField';
import { SelectIdentityField } from '../../../../components/SelectIdentityField/SelectIdentityField';
import { SummaryFileDownload } from '../SummaryFileDownload/SummaryFileDownload';
import { SummaryNotifications } from '../SummaryNotifications/SummaryNotifications';
import { FormFooter } from '../../../../components/FormFooter';
import * as Styled from './styled';
import {
  Form as FormModule,
  Grid,
  Utils,
  Layout as LayoutModule,
} from 'billon-ui';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Row, Col } = Grid;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

export const DocumentPublicationSummary = ({
  onSubmit,
  onBack,
  isLoading,
  defaultValues = {},
  submitButtonText,
  documentType,
  mode = PUBLICATION_MODES.NEW,
  publicationMode,
}) => {
  const { formatMessage } = useIntl();
  const { filters } = useFilters();
  const {
    isTauron,
    isDiploma,
    enableSigning,
    enableActiveDelivery,
    publicationFormConfig,
  } = useConfigContext();
  const { enableRetention } = publicationFormConfig;
  const { getSettingValueByKey } = useSettingsContext();
  const formMethods = useForm({
    defaultValues: defaultValues,
  });
  const { handleSubmit, watch } = formMethods;
  const { mutate: downloadRecipientsList } = useDownloadRecipientsList(
    defaultValues?.jobId,
    defaultValues?.title,
  );

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const signingOrderWatcher = watch('signingOrder');
  const isPrivateOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PRIVATE_ONE_STEP_PUBLISH,
  );
  const { tenantId } = defaultValues || {};
  const displayTime = !isTauron;
  const isPublicDocument = [
    filters?.documentTypeList,
    defaultValues?.documentType,
  ].includes(DOCUMENT_TYPE.PUBLIC);
  const contactDetailsLength = defaultValues?.contactDetails?.length;
  const isDownloadRecipientsFileDisplayed =
    isPublicDocument &&
    (contactDetailsLength > 0 || mode !== PUBLICATION_MODES.NEW);
  const isNotificationSummaryDisplayed =
    !isPublicDocument || (isPublicDocument && contactDetailsLength > 0);

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage
                id="Published by"
                defaultMessage="Published by"
              />
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.PUBLISHED_BY}
              disabled
            />
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage id="Title" defaultMessage="Title" />
              )}
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.TITLE}
              disabled
              placeholder={formatMessage({
                id: 'Document number',
                defaultMessage: 'Document number',
              })}
            />
            <SelectCategoryField disabled />
            {!isPublicDocument &&
              enableSigning &&
              !isPrivateOneStepPublication && (
                <>
                  <SelectSigningOrderField name="signingOrder" disabled />
                  {[
                    SIGNING_ORDER.RECEIVER_ONLY,
                    SIGNING_ORDER.SENDER_FIRST,
                  ].includes(signingOrderWatcher?.value) && (
                    <SelectSigningTypeField disabled />
                  )}
                </>
              )}
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Document version"
                    defaultMessage="Document version"
                  />
                </Text>
                <ControlledTextField name="versionName" disabled />
              </>
            )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Date of issue of the diploma"
                  defaultMessage="Date of issue of the diploma"
                />
              ) : (
                <FormattedMessage
                  id="Valid since"
                  defaultMessage="Valid since"
                />
              )}
            </Text>
            <FormGroup>
              <ControlledCalendarField
                name="validSince"
                disabled
                maxDate={isDiploma ? new Date() : undefined}
                displayTime={displayTime}
                enableTime={false}
              />
            </FormGroup>
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </Text>
                <FormGroup>
                  <ControlledCalendarField
                    name="validUntil"
                    disabled
                    displayTime={displayTime}
                    enableTime={false}
                  />
                </FormGroup>
              </>
            )}
            {!isPublicDocument && <SelectIdentityField disabled />}
            {enableRetention && (
              <SelectRetentionField name="retainUntil" disabled />
            )}
            {enableActiveDelivery && !isPublicDocument && (
              <SelectActiveDeliveryField disabled />
            )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage id="Document" defaultMessage="Document" />
            </Text>
            <FormGroup>
              <SummaryFileDownload file={defaultValues.documentFile[0]} />
            </FormGroup>
            {isDownloadRecipientsFileDisplayed && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Recipients"
                    defaultMessage="Recipients"
                  />
                </Text>
                <FormGroup>
                  {contactDetailsLength > 0 ? (
                    <SummaryFileDownload
                      file={defaultValues?.contactDetails[0]}
                    />
                  ) : (
                    mode !== PUBLICATION_MODES.NEW && (
                      <SummaryFileDownload onClick={downloadRecipientsList} />
                    )
                  )}
                </FormGroup>
              </>
            )}

            {isNotificationSummaryDisplayed && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Notification contents"
                    defaultMessage="Notification contents"
                  />
                </Text>
                <FormGroup>
                  <SummaryNotifications
                    smsContentInput={defaultValues?.smsContentInput}
                    emailContentInput={defaultValues?.emailContentInput}
                    documentType={documentType}
                    signingOrder={defaultValues?.signingOrder?.value}
                    tenantId={tenantId}
                  />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>

        <FormFooter
          onBack={onBack}
          isLoading={isLoading}
          submitButtonText={submitButtonText}
          submitMessageId={'Confirm2'}
        />
      </Styled.Form>
    </FormProvider>
  );
};
