import base from './base';

import { default as tauronUatTdm } from './configs/tauron/tauron.uat.tdm';
import { default as taurondemo } from './configs/tauron/tauron.demo';

import { default as bdsDiploma } from './configs/diploma/diploma.bds';
import { default as diplomaUatTDM } from './configs/diploma/diploma.uat.tdm';
import { default as diplomademo } from './configs/diploma/diploma.demo';

import { default as remotesignatureuat } from './configs/remotSsignature/rs.uat';
import { default as remotesignaturedemo } from './configs/remotSsignature/rs.demo';
import { default as sandbox } from './configs/remotSsignature/sandbox';

import { default as dev } from './configs/dev';
import { default as tergo } from './configs/clients/tergo';

let configType = 'dev';

if (!!process.env.REACT_APP_CONFIG) configType = process.env.REACT_APP_CONFIG;
if (configType == 'dev') {
  console.log('USING DEV CONFIG!');
}

const customerConfig = {
  dev: dev,
  tauronUatTdm: tauronUatTdm,
  bdsDiploma: bdsDiploma,
  diplomaUatTDM: diplomaUatTDM,
  taurondemo: taurondemo,
  SANDBOX: sandbox,
  remotesignaturedemo: remotesignaturedemo,
  diplomademo: diplomademo,
  remotesignatureuat: remotesignatureuat,
  tergo: tergo,
}[configType];

const config = {
  ...base,
  ...customerConfig,
};

export default config;
