import React, { useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

// Hooks
import { useCategoryList } from '../modules/Categories/hooks/useCategoryList';

// Helpers
import { removeUndefinedValues } from '../helpers/removeUndefinedValues';

// Components
import { ShowMoreLabelButton } from '../components/ShowMoreLabelButton/ShowMoreLabelButton';

export const useCategoriesOptions = (isActiveOnly, enabled) => {
  const { formatMessage } = useIntl();
  const [categoryName, setCategoryName] = useState();
  const [pageIncreamentation, setPageIncreamentation] = useState(1);

  const setSearchTextDebounced = React.useRef(
    debounce((callback) => callback(), 700),
  ).current;

  const pageLimit = 50;

  const { data: categoriesData, isFetching: isCategoriesFetching } =
    useCategoryList(
      {
        filters: removeUndefinedValues({
          name: categoryName,
          isActive: isActiveOnly ? true : undefined,
          singleLevel: false,
        }),
        pagination: {
          limit: pageLimit,
          page: pageIncreamentation,
        },
      },
      {
        enabled: enabled,
        refetchOnMount: false,
      },
    );

  const { rows: categoriesRows, count } = categoriesData || {};

  const handlePreviousClick = (e) => {
    e.stopPropagation();
    if (pageIncreamentation > 1) {
      setPageIncreamentation(pageIncreamentation - 1);
    }
  };

  const handleShowMoreClick = (e) => {
    e.stopPropagation();
    setPageIncreamentation(pageIncreamentation + 1);
  };

  const previousOption = {
    value: -2,
    label: (
      <ShowMoreLabelButton
        color="link"
        type="button"
        onClick={handlePreviousClick}
      >
        {formatMessage({ id: 'show previous...' })}
      </ShowMoreLabelButton>
    ),
    disabled: true,
  };

  const showMoreOption = {
    value: -1,
    label: (
      <ShowMoreLabelButton
        color="link"
        type="button"
        onClick={handleShowMoreClick}
      >
        {formatMessage({ id: 'show more...' })}
      </ShowMoreLabelButton>
    ),
    disabled: true,
  };

  const categories = React.useMemo(
    () =>
      (categoriesRows || []).reduce((arr, category) => {
        if (category.isActive) {
          arr.push({
            value: category.id,
            label: category.fullPath,
          });
        }
        return arr;
      }, []),
    [categoriesRows],
  );

  const onChangeCategoryInput = (inputText) => {
    if (inputText !== categoryName) {
      setSearchTextDebounced(() => {
        setPageIncreamentation(1);
        setCategoryName(inputText);
      });
    }
  };

  const categoryOptions = useMemo(() => {
    const rowsCount = categories?.length;

    if (count === 0) {
      return categories;
    }

    if (pageIncreamentation > 1 && rowsCount > 0 && rowsCount === pageLimit) {
      return [previousOption, ...categories, showMoreOption];
    }

    if (
      (pageIncreamentation > 1 && rowsCount >= 0) ||
      (rowsCount === 0 && count > 0)
    ) {
      return [previousOption, ...categories];
    }

    if (count < pageLimit && rowsCount < pageLimit) {
      return categories;
    }

    return [...categories, showMoreOption];
  }, [categories, count, previousOption, showMoreOption, pageIncreamentation]);

  return {
    categories: categoryOptions,
    isCategoriesFetching,
    onChangeCategoryInput,
  };
};
