import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// hooks
import { useSchema } from './useSchema';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// utils
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MAP_VALIDATION_USER_ERRORS_NAMES,
  MAP_VALIDATION_USER_ERRORS,
  MAP_IDENTITY_DOCUMENT_TYPE,
} from '../../../../constraints';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { parseRecipientData } from '../../helpers/formFieldParsers/parseRecipientData';
import { documentTypeOptions } from '../../../../components/SelectIdentityDocumentTypeField/SelectIdentityDocumentTypeField';

// components
import { defaultIdentityDocumentType } from '../../../../components/SelectIdentityDocumentTypeField/SelectIdentityDocumentTypeField';
import DataTab from './DataTab';
import * as Styled from './styled';
import { Form as FormModule, Utils, Grid } from 'billon-ui';

const { Col } = Grid;
const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const CreateForm = ({
  onSubmit,
  isSaving,
  initialValues,
  errors: backendErrors,
  submitMessage,
}) => {
  const { isDiploma } = useConfigContext();

  const schema = useSchema();

  const { documentType } = initialValues || {};

  const identityDocumentTypeInitialValue = useMemo(() => {
    if (isDiploma) return null;
    if (documentType)
      return documentTypeOptions.find(
        (option) => option.value === documentType,
      );
    return defaultIdentityDocumentType;
  }, [isDiploma, documentType]);

  const formMethods = useForm({
    defaultValues: {
      isActive: true,
      ...initialValues,
      documentType: identityDocumentTypeInitialValue,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setError,
    watch,
  } = formMethods;

  const handleFormSubmit = (data) => {
    onSubmit(removeUndefinedValues(parseRecipientData(data)));
  };

  useEffect(() => {
    if (!!backendErrors) {
      Object.entries(backendErrors).forEach(([key, value]) => {
        setError(MAP_VALIDATION_USER_ERRORS_NAMES[key], {
          type: 'response',
          message: MAP_VALIDATION_USER_ERRORS[`${value}_${key}`],
        });
      });
    }
  }, [backendErrors]);

  const documentTypeWatcher = watch('documentType');

  const idLabel = useMemo(() => {
    if (isDiploma) return MAP_IDENTITY_DOCUMENT_TYPE.INDEX_NUMBER;
    if (!documentTypeWatcher) return MAP_IDENTITY_DOCUMENT_TYPE.UNASSIGNED;
    return MAP_IDENTITY_DOCUMENT_TYPE[
      documentTypeWatcher?.value || documentTypeWatcher
    ];
  }, [isDiploma, documentTypeWatcher]);

  return (
    <FormProvider {...formMethods}>
      <Col md={{ size: 8, offset: 2 }}>
        <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
          <DataTab control={control} errors={errors} idLabel={idLabel} />
          <FormGroup>
            {isSaving || isSubmitting ? (
              <ButtonLoader block size="lg" />
            ) : (
              <Button type="submit" size="lg" block>
                {submitMessage ? (
                  submitMessage
                ) : (
                  <FormattedMessage id="Save" defaultMessage="Save" />
                )}
              </Button>
            )}
          </FormGroup>
        </Styled.Form>
      </Col>
    </FormProvider>
  );
};

CreateForm.propTypes = {
  onSubmit: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  errors: PropTypes.object,
  submitMessage: PropTypes.object,
};

export default CreateForm;
