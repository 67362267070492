import React, { useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useCompileNotificationMessages } from '../../../../hooks/useCompileNotificationMessages';
import { useNotificationUserInputs } from '../../../../hooks';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import renderHTML from 'react-render-html';

// constants
import {
  SETTINGS_CONSTANTS,
  SIGNING_ORDER,
  NOTIFICATION_TEMPLATE_TYPE,
  DOCUMENT_TYPE,
} from '../../../../../../constraints';

// Components
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import {
  RenderedNotificationContainer,
  RenderedSMS,
} from '../../../RenderedNotificationContainer/RenderedNotificationContainer';
import { ControlledTextAreaField } from '../../../../../../components/ControlledTextAreaField';
import { FormFooter } from '../../../../../../components/FormFooter';
import { Form as FormModule, Grid, Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule, Text } = Utils;
const { PageLoader } = LoaderModule;
const { FormGroup } = FormModule;
const { Col, Row } = Grid;

export const NotificationContentForm = ({
  onSubmit,
  onBack,
  disabledTitle,
  isSubmitting,
  defaultValues = {},
  documentBlockchainAddress,
  documentType,
}) => {
  const { enableSigning, notificationTheme } = useConfigContext();
  const { inputColumnSize, templateColumnSize } = notificationTheme || {};

  const notificationTemplateType = useMemo(() => {
    if (documentType === DOCUMENT_TYPE.PUBLIC) {
      return NOTIFICATION_TEMPLATE_TYPE.PUBLIC_PUBLISHED;
    }

    const signingOrder = defaultValues?.signingOrder?.value;

    if (!enableSigning || signingOrder === SIGNING_ORDER.NO_SIGNATURE) {
      return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }

    if (
      [SIGNING_ORDER.PUBLISHER_ONLY, SIGNING_ORDER.NO_SIGNATURE].includes(
        signingOrder,
      )
    ) {
      return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }
    if (
      [SIGNING_ORDER.RECEIVER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
        signingOrder,
      )
    ) {
      return NOTIFICATION_TEMPLATE_TYPE.AGREEMENT_READY_TO_SIGN;
    }
  }, [defaultValues, enableSigning, documentType]);

  const formMethods = useForm({
    defaultValues: {
      smsContentInput: '',
      emailContentInput: '',
      ...defaultValues,
    },
  });

  const { tenantId } = defaultValues;

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const { handleSubmit, watch, setValue } = formMethods;

  const emailContentInput = watch('emailContentInput');
  const smsContentInput = watch('smsContentInput');

  const {
    emailPreviewValue,
    smsContentPreviewValue,
    isEmailUserInputPresent,
    isSmsUserInputPresent,
    isLoading,
    error,
  } = useCompileNotificationMessages(
    emailContentInput,
    smsContentInput,
    notificationTemplateType,
    tenantId,
  );

  const areDefaultInputsExpected = documentBlockchainAddress !== undefined;

  const { isLoading: areDefaultInputsLoading, error: defaultInputsError } =
    useNotificationUserInputs(documentBlockchainAddress, {
      onSuccess: ({ emailUserInput, smsUserInput }) => {
        setValue('emailContentInput', emailUserInput);
        setValue('smsContentInput', smsUserInput);
      },
      enabled: areDefaultInputsExpected,
    });

  const { getSettingValueByKey } = useSettingsContext();
  const maxSmsLength =
    getSettingValueByKey(SETTINGS_CONSTANTS.MAX_SMS_USER_INPUT_LENGTH) || 70;

  const smsValueWatcher = watch('smsContentInput');

  if (isLoading || areDefaultInputsLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (error || defaultInputsError) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        {!disabledTitle && (
          <Text as="h2" fontWeight={800}>
            <FormattedMessage
              id="Notification messages"
              defaultMessage="Notification messages"
            />
            <br />
          </Text>
        )}

        <Text as="h4" fontWeight={800}>
          <FormattedMessage
            id="Email notification"
            defaultMessage="Email notification"
          />
        </Text>
        <Row>
          {isEmailUserInputPresent && (
            <Col md={inputColumnSize}>
              <FormGroup>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Enter message"
                    defaultMessage="Enter message"
                  />
                </Text>
                <ControlledTextAreaField
                  name="emailContentInput"
                  maxLength={null}
                />
              </FormGroup>
            </Col>
          )}
          <Col md={!isEmailUserInputPresent ? 12 : templateColumnSize}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedNotificationContainer>
                {renderHTML(emailPreviewValue)}
              </RenderedNotificationContainer>
            </FormGroup>
          </Col>
        </Row>
        <Text as="h4" fontWeight={800}>
          <FormattedMessage
            id="SMS notification"
            defaultMessage="SMS notification"
          />
        </Text>
        <Row>
          {isSmsUserInputPresent && (
            <Col md={inputColumnSize}>
              <FormGroup>
                <Styled.LabelFlexRow>
                  <Text as="h6" fontSize="12px" fontWeight="600">
                    <FormattedMessage
                      id="Enter message"
                      defaultMessage="Enter message"
                    />
                  </Text>
                  <Text as="h6" fontSize="12px" fontWeight="600">
                    <FormattedMessage
                      id="Number of characters"
                      defaultMessage="Number of characters"
                    />{' '}
                    {smsValueWatcher?.length || 0}
                    {'/'}
                    {maxSmsLength}
                  </Text>
                </Styled.LabelFlexRow>
                <ControlledTextAreaField
                  name="smsContentInput"
                  maxLength={maxSmsLength}
                />
              </FormGroup>
            </Col>
          )}
          <Col md={!isSmsUserInputPresent ? 12 : templateColumnSize}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedSMS>{smsContentPreviewValue}</RenderedSMS>
            </FormGroup>
          </Col>
        </Row>
        <FormFooter onBack={onBack} isLoading={isSubmitting} />
      </Styled.Form>
    </FormProvider>
  );
};
