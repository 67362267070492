import React, { useMemo, useState } from 'react';
import { useCompileNotificationMessages } from '../../hooks/useCompileNotificationMessages';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import renderHTML from 'react-render-html';

// constants
import {
  DOCUMENT_TYPE,
  NOTIFICATION_TEMPLATE_TYPE,
  SIGNING_ORDER,
} from '../../../../constraints';

// Components
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { RenderedNotificationContainer } from '../RenderedNotificationContainer/RenderedNotificationContainer';
import { Utils, Modal as ModalModule } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;
const { Modal } = ModalModule;

export const SummaryNotifications = ({
  smsContentInput = '',
  emailContentInput = '',
  documentType,
  tenantId,
  signingOrder,
}) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);

  const { enableSigning } = useConfigContext();

  const notificationTemplateType = useMemo(() => {
    if (documentType === DOCUMENT_TYPE.PUBLIC) {
      return NOTIFICATION_TEMPLATE_TYPE.PUBLIC_PUBLISHED;
    }
    if (!enableSigning || signingOrder === SIGNING_ORDER.NO_SIGNATURE) {
      return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }

    if (signingOrder === SIGNING_ORDER.PUBLISHER_ONLY) {
      return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }
    if (signingOrder === SIGNING_ORDER.RECEIVER_ONLY) {
      return NOTIFICATION_TEMPLATE_TYPE.AGREEMENT_READY_TO_SIGN;
    }
  }, [signingOrder, enableSigning, documentType]);

  const { emailPreviewValue, smsContentPreviewValue, isLoading, error } =
    useCompileNotificationMessages(
      emailContentInput,
      smsContentInput,
      notificationTemplateType,
      tenantId,
    );

  const handleToggleEmailModal = () => {
    setIsEmailModalOpen(!isEmailModalOpen);
  };

  const handleToggleSmsModal = () => {
    setIsSmsModalOpen(!isSmsModalOpen);
  };

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Styled.SummaryContainer>
        <Styled.IconButtonWrapper>
          <IconButton
            icon="envelope"
            iconSize="2x"
            onClick={handleToggleEmailModal}
          />
        </Styled.IconButtonWrapper>
        <Styled.IconButtonWrapper>
          <IconButton
            icon="comment-alt"
            iconSize="2x"
            onClick={handleToggleSmsModal}
          />
        </Styled.IconButtonWrapper>
      </Styled.SummaryContainer>
      <Modal
        toggle={handleToggleEmailModal}
        isOpen={isEmailModalOpen}
        title={
          <FormattedMessage
            id="Email notification"
            defaultMessage="Email notification"
          />
        }
      >
        <RenderedNotificationContainer>
          {renderHTML((emailPreviewValue || '').replace('{{USER_INPUT}}', ''))}
        </RenderedNotificationContainer>
      </Modal>
      <Modal
        toggle={handleToggleSmsModal}
        isOpen={isSmsModalOpen}
        title={
          <FormattedMessage
            id="SMS notification"
            defaultMessage="SMS notification"
          />
        }
      >
        <RenderedNotificationContainer>
          {smsContentPreviewValue}
        </RenderedNotificationContainer>
      </Modal>
    </>
  );
};
