import { PUBLICATION_MODES } from '../../../constraints';
import { calculateRetainUntil } from './calculateRetainUntil';

export const parsePublishRequestData = (
  {
    title,
    identity,
    validSince,
    validUntil,
    category,
    contactDetails,
    documentFile,
    tenantId,
    retainUntil,
    signingOrder,
    signingType,
    activeDeliveryType,
    ...rest
  },
  publicationMode,
  documentBlockchainAddress,
  retainUntilDate,
) => ({
  ...rest,
  publicationMode,
  previousDocumentBlockchainAddress:
    publicationMode !== PUBLICATION_MODES.NEW
      ? documentBlockchainAddress
      : undefined,
  title,
  identity: identity?.value
    ? {
        id: identity?.internalId ?? identity?.value,
      }
    : undefined,
  validSince,
  validUntil,
  retainUntil: retainUntil
    ? calculateRetainUntil(retainUntil, publicationMode, retainUntilDate)
    : undefined,
  category: {
    id: category.value,
  },
  contactDetails,
  documentFile,
  tenant: tenantId ? { id: tenantId } : undefined,
  signingOrder: signingOrder ? signingOrder.value : undefined,
  activeDeliveryType: activeDeliveryType ? activeDeliveryType.value : undefined,
});
