import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  MAP_RECIPIENT_STATUSES,
  MAP_UPDATE_IDENTITY_STATUSES,
  MAP_UPDATE_IDENTITY_STATUSES_VALUES,
  MAP_RECIPIENT_INFOS,
  RECIPIENT_STATUSES,
  mapERRORS,
} from '../../constraints';
import { RecipientStatusToolTip } from './components';
import { generateRecordBasedId } from '../../utils';
import { DisabledContent } from '../../components/DisabledContent/DisabledContent';
import { getDocumentTypeText } from './helpers/getDocumentTypeText';

export const disableableMultiFormatter =
  (formatter, isPointerEnabled) => (value, record) => {
    const isOverlayActive = record?.isGdprSuspended;
    if (isOverlayActive) {
      return (
        <DisabledContent
          isDisabled={isOverlayActive}
          isPointerEnabled={isPointerEnabled}
        >
          {formatter(value, record)}
        </DisabledContent>
      );
    } else return formatter(value, record);
  };

export const disableableSingleFormatter = (value, record) => {
  return (
    <DisabledContent isDisabled={record?.isGdprSuspended}>
      {value}
    </DisabledContent>
  );
};

export const recipientStatusFormatter = (value) => {
  const StatusMessageId =
    MAP_RECIPIENT_STATUSES[value] || mapERRORS.UNHANDLED_STATUS;

  return (
    <FormattedMessage id={StatusMessageId} defaultMessage={StatusMessageId} />
  );
};

export const recipientStatusWithToolTipFormatter = (value, record) => {
  const { isGdprSuspended, updateInfo } = record || {};
  const { status: updatingStatus } = updateInfo || {};
  let mappedValue = value;
  if (isGdprSuspended) {
    mappedValue = RECIPIENT_STATUSES.SUSPENDED;
  }
  if (updatingStatus === RECIPIENT_STATUSES.PUBLISHING) {
    mappedValue = RECIPIENT_STATUSES.PUBLISHING;
  }
  const content = MAP_RECIPIENT_INFOS[mappedValue] || mapERRORS.UNHANDLED;
  const label = recipientStatusFormatter(mappedValue);
  const id = generateRecordBasedId(record);
  return <RecipientStatusToolTip content={content} id={id} label={label} />;
};

export const recipientUpdateStatusFormatter = (value) => {
  const StatusMessageId =
    MAP_UPDATE_IDENTITY_STATUSES_VALUES[value] || mapERRORS.UNHANDLED_STATUS;

  return (
    <FormattedMessage id={StatusMessageId} defaultMessage={StatusMessageId} />
  );
};

export const recipientUpdateInfoStatusWithToolTipFormatter = (
  updateInfo,
  record,
) => {
  const { status: updatingStatus } = updateInfo || {};
  const content =
    MAP_UPDATE_IDENTITY_STATUSES[updatingStatus] || mapERRORS.UNHANDLED;
  const label = recipientUpdateStatusFormatter(updatingStatus);
  const id = generateRecordBasedId({ ...record, useCase: 'updateInfo' });
  return <RecipientStatusToolTip content={content} id={id} label={label} />;
};

export const recipientUpdateStatusWithToolTipFormatter = (
  updateStatus,
  record,
) => {
  const content =
    MAP_UPDATE_IDENTITY_STATUSES[updateStatus] || mapERRORS.UNHANDLED;
  const label = recipientUpdateStatusFormatter(updateStatus);
  const id = generateRecordBasedId({ ...record, useCase: 'updateStatus' });
  return <RecipientStatusToolTip content={content} id={id} label={label} />;
};

export const recipientGdprSuspendedFormatter = (value) => {
  const GdprSuspendedMessageId = value ? 'Yes' : 'No';

  return (
    <FormattedMessage
      id={GdprSuspendedMessageId}
      defaultMessage={GdprSuspendedMessageId}
    />
  );
};

export const recipientDocumentTypeFormatter = (isDiploma) => (documentType) => {
  const documentTypeMessageId = getDocumentTypeText(isDiploma, documentType);
  return (
    <FormattedMessage
      id={documentTypeMessageId}
      defaultMessage={documentTypeMessageId}
    />
  );
};
