import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

// hooks
import { usePatchRecipientsFile } from '../../../../hooks/usePatchRecipientsFile';

// constants
import {
  CUSTOMER,
  DOCUMENT_TYPE,
  RECIPIENT_ACTIONS,
} from '../../../../../../constraints';
import {
  RECIPIENT_ERROR_CODES,
  RECIPIENT_VALIDATION_PURPOSE,
} from '../../../../../../constants';

import { LoadingActionButton } from '../../../../../../components';
import { RecipientsFileForm } from '../../../PublishDocumentModal/components/RecipientsFileForm';
import UpdateRecipientListToolTip from '../UpdateRecipientListToolTip';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import FileErrorSummary from '../../../PublishDocumentModal/components/RecipientsFileForm/components/FileErrorSummary';

import { Modal as ModalModule } from 'billon-ui';

const { Modal, SuccessModal } = ModalModule;

export const UpdateRecipientListButton = ({ documentType, customer }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isUpdateModalErrorOpen, setIsUpdateModalErrorOpen] = useState(false);
  const { id } = useParams();

  const handleToggleUpdateModal = () => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  };

  const handleToggleUpdateErrorModal = () => {
    setIsUpdateModalErrorOpen(!isUpdateModalErrorOpen);
  };

  const {
    mutate: patchRecipientsFile,
    isSuccess: isPatchSuccess,
    reset,
    error,
  } = usePatchRecipientsFile({
    onSuccess: () => {
      setIsUpdateModalOpen(false);
    },
    onError: () => {
      setIsUpdateModalErrorOpen(true);
    },
  });

  const handleRecipientListSubmit = (data) => {
    patchRecipientsFile({ id, data });
  };

  const handleCloseSuccessModal = () => {
    reset();
  };

  const handleFixErrors = () => {
    reset();
    setIsUpdateModalErrorOpen(false);
  };

  const handleExit = () => {
    handleToggleUpdateErrorModal();
    handleToggleUpdateModal();
  };

  if (documentType === DOCUMENT_TYPE.PUBLIC && customer !== CUSTOMER.DIPLOMA) {
    return null;
  }

  if (error && isUpdateModalErrorOpen) {
    const errorData = error?.response?.data;
    const statusCode =
      errorData?.statusCode || RECIPIENT_ERROR_CODES.INTERNAL_SERVER_ERROR;
    return (
      <>
        <LoadingActionButton onClick={handleToggleUpdateModal} outline>
          <FormattedMessage
            id="Update recipient list"
            defaultMessage="Update recipient list"
          />
        </LoadingActionButton>
        <ErrorModal
          isOpen={isUpdateModalErrorOpen}
          toggle={handleToggleUpdateErrorModal}
          onClose={handleToggleUpdateErrorModal}
          closeIconEnabled
          customClose
        >
          {RECIPIENT_ERROR_CODES[statusCode] && (
            <>
              <br />
              <FileErrorSummary
                mode={RECIPIENT_ACTIONS.EXTERNAL_VALIDATION}
                errorData={errorData}
                handleResetMutation={handleFixErrors}
                handleExit={handleExit}
                validationPurpose={RECIPIENT_VALIDATION_PURPOSE.UPDATE}
              />
            </>
          )}
        </ErrorModal>
      </>
    );
  }

  return (
    <>
      <LoadingActionButton onClick={handleToggleUpdateModal} outline>
        <FormattedMessage
          id="Update recipient list"
          defaultMessage="Update recipient list"
        />
      </LoadingActionButton>
      <Modal
        isOpen={isUpdateModalOpen}
        toggle={handleToggleUpdateModal}
        onClose={handleToggleUpdateModal}
      >
        <RecipientsFileForm
          backBtnDisabled={true}
          onSubmit={handleRecipientListSubmit}
          mode={RECIPIENT_ACTIONS.UPDATE_LIST}
          customTitle={
            <>
              <FormattedMessage
                id="Updating the list of recipients"
                defaultMessage="Updating the list of recipients"
              />{' '}
              <UpdateRecipientListToolTip />
            </>
          }
          submitMessageId="Confirm2"
          handleCancelProcess={handleExit}
          validationPurpose={RECIPIENT_VALIDATION_PURPOSE.UPDATE}
        />
      </Modal>
      <SuccessModal
        isOpen={isPatchSuccess}
        title={
          <FormattedMessage
            id="Recipients file updated"
            defaultMessage="Recipients file updated"
          />
        }
        onClose={handleCloseSuccessModal}
      >
        <FormattedMessage
          id="Recipients file updated successfully"
          defaultMessage="Recipients file updated successfully"
        />
      </SuccessModal>
    </>
  );
};
