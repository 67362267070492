import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';

// components
import { SelectIdentityDocumentTypeField } from '../../../../components/SelectIdentityDocumentTypeField/SelectIdentityDocumentTypeField';
import * as Styled from './styled';
import { Form as FormModule } from 'billon-ui';

const { FormGroup, TextField, PhoneNumberField, SwitchField } = FormModule;

const DataTab = ({ control, errors, idLabel }) => {
  const { isDiploma, isTauron } = useConfigContext();

  return (
    <>
      <FormGroup>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={<FormattedMessage id="Email" defaultMessage="Email" />}
                placeholder="jan.kowalski@adres.com"
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <PhoneNumberField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={
                  <FormattedMessage
                    id="Phone number"
                    defaultMessage="Phone number"
                  />
                }
              />
            );
          }}
        />
      </FormGroup>
      {!isDiploma && (
        <FormGroup>
          <SelectIdentityDocumentTypeField />
        </FormGroup>
      )}

      <FormGroup>
        <Controller
          name="documentNumber"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={
                  <FormattedMessage id={idLabel} defaultMessage={idLabel} />
                }
                placeholder="88903332983"
              />
            );
          }}
        />
      </FormGroup>
      {isTauron && (
        <FormGroup>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={
                    <FormattedMessage
                      id="Name and surname"
                      defaultMessage="Name and surname"
                    />
                  }
                  placeholder="Jan Kowalski"
                />
              );
            }}
          />
        </FormGroup>
      )}
      {!isTauron && (
        <>
          <FormGroup>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    input={field}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                      error: errors?.[field.name]?.message,
                    }}
                    label={
                      <FormattedMessage
                        id="First name"
                        defaultMessage="First name"
                      />
                    }
                    placeholder="Jan"
                  />
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    input={field}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                      error: errors?.[field.name]?.message,
                    }}
                    label={
                      <FormattedMessage
                        id="Last name"
                        defaultMessage="Last name"
                      />
                    }
                    placeholder="Kowalski"
                  />
                );
              }}
            />
          </FormGroup>
        </>
      )}
      <FormGroup>
        <Styled.FieldFlexRow>
          <Styled.FC2>
            <FormattedMessage id="Is active?2" defaultMessage="Is active?2" />
          </Styled.FC2>
          <Styled.FC>
            <Controller
              name="isActive"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SwitchField
                    input={{
                      ...field,
                      checked: field.value,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                      error: errors?.[field.name]?.message,
                    }}
                    type="checkbox"
                  />
                );
              }}
            />
          </Styled.FC>
        </Styled.FieldFlexRow>
      </FormGroup>
    </>
  );
};

DataTab.propTypes = {
  initialValues: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default DataTab;
