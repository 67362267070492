export default (value) => {
  if (!value) {
    return true;
  }

  const reg = /^[0-9]{11}$/;
  if (!reg.test(value)) {
    return false;
  }

  const digits = `${value}`.split('');
  if (
    parseInt(value.substring(4, 6), 10) > 31 ||
    parseInt(value.substring(2, 4), 10) > 12
  ) {
    return false;
  }

  let checksum =
    (1 * parseInt(digits[0], 10) +
      3 * parseInt(digits[1], 10) +
      7 * parseInt(digits[2], 10) +
      9 * parseInt(digits[3], 10) +
      1 * parseInt(digits[4], 10) +
      3 * parseInt(digits[5], 10) +
      7 * parseInt(digits[6], 10) +
      9 * parseInt(digits[7], 10) +
      1 * parseInt(digits[8], 10) +
      3 * parseInt(digits[9], 10)) %
    10;
  if (checksum === 0) {
    checksum = 10;
  }
  checksum = 10 - checksum;

  return parseInt(digits[10], 10) === checksum;
};
