import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Grid, Utils } from 'billon-ui';
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { RenderedSMS } from '../../../../../RenderedNotificationContainer/RenderedNotificationContainer';
import * as Styled from './styled';
import Modal from '../../../../../../../../ui/Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { SubtitleToolTip } from '..';
import { SETTINGS_CONSTANTS } from '../../../../../../../../constraints';
import { useSettingsContext } from '../../../../../../../Settings/hooks/useSettings';
import { useFormContext } from 'react-hook-form';
import { useConfigContext } from '../../../../../../../Config/hooks/useConfig';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Col, Row } = Grid;

const SmsNotificationModal = ({
  isOpen,
  onClose,
  smsContentPreviewValue,
  isEditable,
}) => {
  const { getSettingValueByKey } = useSettingsContext({});
  const maxSmsLength =
    getSettingValueByKey(SETTINGS_CONSTANTS.MAX_SMS_USER_INPUT_LENGTH) || 70;
  const { notificationTheme } = useConfigContext();
  const { inputColumnSize, templateColumnSize } = notificationTheme || {};
  const { watch } = useFormContext();
  const smsValueWatcher = watch('smsUserInput');

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <FormattedMessage
          id="SMS notification message"
          defaultMessage="SMS notification message"
        />
      </ModalHeader>

      <Styled.ModalBodyWrapper>
        <Row>
          {isEditable && (
            <Col md={inputColumnSize}>
              <Text as="h5" fontWeight="600">
                <FormattedMessage
                  id="Edit message"
                  defaultMessage="Edit message"
                />{' '}
                <SubtitleToolTip />
              </Text>
              <FormGroup>
                <Styled.LabelFlexRow>
                  <Text as="h6" fontSize="12px" fontWeight="600">
                    <FormattedMessage
                      id="Enter message"
                      defaultMessage="Enter message"
                    />
                  </Text>
                  <Text as="h6" fontSize="12px" fontWeight="600">
                    <FormattedMessage
                      id="Number of characters"
                      defaultMessage="Number of characters"
                    />{' '}
                    {smsValueWatcher?.length || 0}
                    {'/'}
                    {maxSmsLength}
                  </Text>
                </Styled.LabelFlexRow>

                <ControlledTextAreaField
                  name="smsUserInput"
                  maxLength={maxSmsLength}
                />
              </FormGroup>
            </Col>
          )}

          <Col md={!isEditable ? 12 : templateColumnSize}>
            <Text as="h5" fontWeight="600">
              <FormattedMessage id="Preview" defaultMessage="Preview" />
            </Text>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedSMS>
                {isEditable
                  ? smsContentPreviewValue
                  : smsContentPreviewValue?.replace('{{USER_INPUT}}', '')}
              </RenderedSMS>
            </FormGroup>
          </Col>
        </Row>
      </Styled.ModalBodyWrapper>
      <ModalFooter onBack={onClose} onNext={onClose} nextAlignLeft={true} />
    </Modal>
  );
};

export default SmsNotificationModal;
