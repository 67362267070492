import {
  MAP_IDENTITY_DOCUMENT_TYPE,
  IDENTITY_DOCUMENT_TYPE,
} from '../../../constraints';

export const getDocumentTypeText = (isDiploma, documentType) => {
  if (isDiploma) {
    return MAP_IDENTITY_DOCUMENT_TYPE.INDEX_NUMBER;
  }
  if (documentType === IDENTITY_DOCUMENT_TYPE.PESEL) {
    return MAP_IDENTITY_DOCUMENT_TYPE.PESEL;
  }
  if (documentType === IDENTITY_DOCUMENT_TYPE.OTHER) {
    return MAP_IDENTITY_DOCUMENT_TYPE.OTHER;
  }
  return MAP_IDENTITY_DOCUMENT_TYPE.UNASSIGNED;
};
