import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

// constants
import {
  RECIPIENT_STATUSES,
  RECIPIENT_FILTER_FIELD_NAMES,
} from '../../../../constraints';

// helpers
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { parseFilters } from '../../helpers/parseFilters';
import { getExternalIdText } from '../../helpers/getExternalIdText';

// hooks
import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';
import useFilters from '../../../../hooks/useFilters';
import { useRecipientFormDefaultValues } from './hooks/useRecipientFormDefaultValues';

// components
import { SelectIdentityDocumentTypeField } from '../../../../components/SelectIdentityDocumentTypeField/SelectIdentityDocumentTypeField';
import { ControlledTextField } from '../../../../components/ControlledTextField';
import {
  TextNameField,
  TextFirstNameField,
  TextLastNameField,
  TextCreatedByField,
  TextEmailField,
  TextPhoneNumberField,
  SelectStatusField,
  CalendarCreatedAtField,
} from './components';
import {
  FormHeader,
  FormActions,
  FieldLabel,
  FlexStartRow,
  FilterCard,
} from '../../../../components/Form';

import { Grid } from 'billon-ui';
const { Col, Row } = Grid;

export const FilterForm = ({ initialValues: defaultFilters, closeFilters }) => {
  const { navigateWithNewSearchQuery, onClear, onSingleFilterClear } =
    useFilters();

  const { isDiploma, isTauron } = useConfigContext();
  const { formInitialValues, computedDefaultValues } =
    useRecipientFormDefaultValues(defaultFilters);

  const externalIdText = getExternalIdText(isDiploma);
  const isExternalIdTwoStep = !isDiploma;

  const formMethods = useForm({
    defaultValues: computedDefaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    resetField,
  } = formMethods;

  const onClearFilters = () => {
    onClear();
    reset(formInitialValues);
  };
  const onSingleFieldClear = (name) => {
    resetField(name);
    onSingleFilterClear(name, defaultFilters?.filters);
  };
  const onFieldClear = (name) => onSingleFieldClear(name);

  const statusWatcher = watch('status');
  const onStatusClear = (name) => {
    if (
      [statusWatcher?.value, statusWatcher[0]?.value].includes(
        RECIPIENT_STATUSES.SUSPENDED,
      )
    ) {
      onSingleFieldClear(RECIPIENT_FILTER_FIELD_NAMES.IS_GDPR_SUSPENDED);
    } else {
      onSingleFieldClear(name);
    }
  };

  const onSubmit = (values) => {
    navigateWithNewSearchQuery({
      filters: merge(
        defaultFilters.filters,
        removeUndefinedValues(parseFilters(values)),
      ),
      pagination: {
        page: 1,
      },
    });
  };

  return (
    <FilterCard>
      <FormHeader onClick={closeFilters} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexStartRow>
            {isTauron && (
              <Col lg={12} xl={6}>
                <TextNameField name={RECIPIENT_FILTER_FIELD_NAMES.NAME} />
              </Col>
            )}
            {!isTauron && (
              <Col lg={6} xl={3}>
                <TextFirstNameField
                  name={RECIPIENT_FILTER_FIELD_NAMES.FIRST_NAME}
                />
              </Col>
            )}
            {!isTauron && (
              <Col lg={6} xl={3}>
                <TextLastNameField
                  name={RECIPIENT_FILTER_FIELD_NAMES.LAST_NAME}
                />
              </Col>
            )}
            <Col lg={6} xl={3}>
              <FieldLabel>
                <FormattedMessage
                  id={externalIdText}
                  defaultMessage={externalIdText}
                />
              </FieldLabel>
              {isExternalIdTwoStep ? (
                <Row>
                  <Col sm={6}>
                    <SelectIdentityDocumentTypeField
                      name={RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE}
                      disabled={defaultFilters?.id ? true : false}
                      isDefaultLabelEnabled={false}
                    />
                  </Col>
                  <Col sm={6}>
                    <ControlledTextField
                      name={RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER}
                    />
                  </Col>
                </Row>
              ) : (
                <ControlledTextField
                  name={RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER}
                />
              )}
            </Col>
            <Col lg={6} xl={3}>
              <TextCreatedByField
                name={RECIPIENT_FILTER_FIELD_NAMES.CREATED_BY}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextEmailField name={RECIPIENT_FILTER_FIELD_NAMES.EMAIL} />
            </Col>
            <Col lg={6} xl={3}>
              <TextPhoneNumberField
                name={RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER}
              />
            </Col>
            <Col lg={6} xl={3}>
              <SelectStatusField
                name={RECIPIENT_FILTER_FIELD_NAMES.STATUS}
                isClearable
                onClear={onStatusClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <CalendarCreatedAtField
                name={RECIPIENT_FILTER_FIELD_NAMES.CREATED_AT_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
          </FlexStartRow>
          <FormActions
            onClearFilters={onClearFilters}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </FilterCard>
  );
};
