import React, { createContext, useContext } from 'react';
import config from '../../../config';
import { CUSTOMER } from '../../../constraints';
import { notificationTheme } from '../constants/notificationTheme';

const ConfigContext = createContext(false);

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
  const {
    customer,
    enablePublic,
    enablePrivate,
    enableSigning,
    enableActiveDelivery,
    enablePublicationDelay,
    enableReports,
  } = config;

  const isDiploma = customer === CUSTOMER.DIPLOMA;
  const isTauron = customer === CUSTOMER.TAURON;
  const isDefault = customer === CUSTOMER.DEFAULT;

  const publicationFormConfig = {
    enableRetention: !isTauron && !isDiploma,
    isValidUntilRequired: isDefault,
    displayTime: !isTauron && !isDiploma,
    enableTenants: isTauron,
  };

  const usersConfig = {
    enableAdministrativePasswordChange: isTauron,
  };

  return (
    <ConfigContext.Provider
      value={{
        customer,
        enablePublic,
        enablePrivate,
        enableSigning,
        enableActiveDelivery,
        enablePublicationDelay,
        enableReports,
        config,
        isDiploma,
        isTauron,
        isDefault,
        publicationFormConfig,
        usersConfig,
        notificationTheme,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
